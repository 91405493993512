@include block('withdrawal-form') {
  @include element('label') {
    display: block;
    margin-bottom: rem(4px);
    color: $--color-greyscale-400;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @include element('information') {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 20px;
    border: 2px solid $--color-lightblue-200;
    border-radius: 8px;
    padding: rem(18px 22px);
    background-color: $--color-lightblue-100;

    > span {
      line-height: 1;
    }
  }

  @include element('caution-icon') {
    margin-top: -1px;
    margin-right: rem(14px);
    font-size: 1rem;
    color: #5bc8ec;
  }

  @include element('btn') {
    margin-right: auto;
    margin-left: auto;
    padding-top: 8.24px;
    padding-bottom: 8.24px;
    font-size: 20px;
    width: 132px;
    @include media-breakpoint-up(md) {
      width: 160px;
    }

    &:focus {
      outline: 1px solid $--color-primary-1;
    }
  }
}
