@import 'libs/uikit/src/styles/global';

.container {
  width: 100%;
  display: grid;
  gap: 10px;

  .box {
    display: grid;
    grid-template-areas:
      'img title icon'
      'img text icon';
    grid-template-columns: 56px auto 20px;
    background-color: var(--color-greyscale-100);
    border: 1px solid var(--color-white-200);
    border-radius: 4px;
    padding: 16px 16px;
    color: var(--color-black);
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    .img {
      grid-area: img;
      margin-right: auto 0 auto auto;
      width: 36px;
      height: auto;
    }

    .icon {
      grid-area: img;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      width: 36px;
      height: 36px;

      & > svg {
        color: var(--color-white);
        width: 26px;
        height: 26px;

        path {
          fill: var(--color-white);
        }
      }
    }

    .title {
      grid-area: title;
      font-weight: 500;
      line-height: 18px;
      font-size: rem(24px);
    }

    .text {
      grid-area: text;
      color: var(--color-greyscale-400);
      font-weight: normal;
      font-size: rem(20px);
      line-height: 18px;
    }

    .right-icon {
      grid-area: icon;
      color: var(--color-greyscale-400);
    }
  }
}
