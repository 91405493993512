@include block('create-avatar') {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include element('container') {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
  @include element('title') {
    font-size: 20px;
  }
  @include element('avatar-container') {
    margin-top: rem(16px);
    margin-bottom: rem(24px);
  }
  @include element('avatar-name') {
    margin-top: rem(2px);
    text-align: center;
    font-size: rem(16px);
    font-weight: 400;
    color: $--color-greyscale-600;
  }
  @include element('avatar') {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    margin: 0 auto;
    border: 3px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    img {
      pointer-events: none;
    }
    @include modifier('active') {
      border: 3px solid $--color-violet-400;
      filter: drop-shadow(rem(0px 2px 4px) rgba(0, 0, 0, 0.25));
    }
  }
  @include element('info-container') {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include element('text-link') {
    margin-top: 16px;
    .ps-text-link__body {
      text-decoration: underline;
    }
  }
}

@include block('privacy-layout') {
  font-size: rem(20px);
  line-height: rem(24px);
  margin-bottom: rem(16px);

  @include element('button') {
    font-size: rem(24px);
    color: $--color-violet-400;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: rem(24px);
        height: rem(24px);
      }
    }
  }
  @include element('content') {
    font-family: inherit;
    text-align: center;
    padding-right: 0;

    @include media-breakpoint-only(xs) {
      flex: 1 1 auto !important;
    }
  }

  @include element('text-link') {
    @include media-breakpoint-up(md) {
      margin-left: 0;
      padding-left: rem(4px);
    }
  }
}
