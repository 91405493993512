@include block('select-qr-icon') {
  width: rem(48px);
  height: rem(63px);
  @include media-breakpoint-up(md) {
    width: rem(51px);
    height: rem(66px);
  }
  @include media-breakpoint-up(xl) {
    width: rem(74px);
    height: rem(96px);
  }
}
@include block('select-transfer-icon') {
  width: rem(58px);
  height: rem(58px);
  @include media-breakpoint-up(md) {
    width: rem(62px);
    height: rem(62px);
  }
  @include media-breakpoint-up(xl) {
    width: rem(90px);
    height: rem(90px);
  }
}
@include block('select-truemoney-icon') {
  width: rem(68px);
  height: rem(62px);
  @include media-breakpoint-up(md) {
    width: rem(71px);
    height: rem(65px);
  }
  @include media-breakpoint-up(xl) {
    width: rem(104px);
    height: rem(95px);
  }
}

@include block('select-card') {
  display: flex;
  line-height: rem(20px);

  @include element('layout') {
    width: 100%;
    margin-bottom: rem(15px);
    filter: grayscale(1);
    &:not(:first-child) {
      margin-left: rem(8px);
    }

    // THIS : ACTIVE STYLE
    @include modifier('active') {
      filter: grayscale(0);
      @include element('item') {
        border: 2px solid $--color-primary-1;
      }
      @include element('detail') {
        color: $--color-primary-1;
      }
    }
  }

  @include element('item') {
    width: 100%;
    height: rem(102px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $--color-greyscale-200;
    border-radius: $--border-radius-min;
    transition: 0.3s;
    cursor: pointer;
    background-color: $--color-white;
    box-shadow: none;

    @include media-breakpoint-up(md) {
      height: rem(109px);
    }

    @include media-breakpoint-up(xl) {
      height: rem(160px);
    }
  }

  @include element('detail') {
    font-weight: 500;
    margin-top: rem(8px);
  }

  @include modifier('active') {
    border: 2px solid $--color-primary-1;
    @include element('detail') {
      color: $--color-violet-400;
    }
  }
}
