/**
 * Shadows
 */
.ps-box-shadow-1 {
  box-shadow: $--box-shadow-1;
}
.ps-box-shadow-2 {
  box-shadow: $--box-shadow-2;
}
.ps-box-shadow-3 {
  box-shadow: $--box-shadow-3;
}
.ps-box-shadow-4 {
  box-shadow: $--box-shadow-4;
}

/**
 * Border
 */
.ps-border-radius-1 {
  border-radius: $--border-radius-1;
}
.ps-border-radius-2 {
  border-radius: $--border-radius-2;
}
.ps-border-radius-3 {
  border-radius: $--border-radius-3;
}
.ps-border-radius-4 {
  border-radius: $--border-radius-4;
}
.ps-border-radius-h {
  border-radius: $--border-radius-h;
}

/**
 * Text Colors
 */
.ps-text-primary,
.ps-text-primary-1 {
  color: $--color-primary-1;
}
.ps-text-primary--force {
  color: $--color-primary-1 !important;
}
.ps-text-primary-2 {
  color: $--color-primary-2;
}
.ps-text-success {
  color: $--color-success;
}
.ps-text-warning {
  color: $--color-warning;
}
.ps-text-danger {
  color: $--color-danger;
}
.ps-text-black {
  color: $--color-black;
}
.ps-text-white {
  color: $--color-white;
}
.ps-text-greyscale,
.ps-text-greyscale-1 {
  color: $--color-greyscale-100;
}
.ps-text-default,
.ps-text-greyscale-2 {
  color: $--color-greyscale-400;
}
.ps-text-greyscale-4 {
  color: $--color-greyscale-400;
}
.ps-text-none {
  color: $--color-greyscale-600;
}
.ps-text-blue-2 {
  color: $--color-blue-200;
}

/**
 * Background Colors
 */
.ps-bg-primary,
.ps-bg-primary-1 {
  background-color: $--color-primary-1;
}
.ps-bg-primary-2 {
  background-color: $--color-primary-2;
}
.ps-bg-success {
  background-color: $--color-success;
}
.ps-bg-warning {
  background-color: $--color-warning;
}
.ps-bg-danger {
  background-color: $--color-danger;
}
.ps-bg-black {
  background-color: $--color-black;
}
.ps-bg-white {
  background-color: $--color-white;
}
.ps-bg-greyscale,
.ps-bg-greyscale-1 {
  background-color: $--color-greyscale-100;
}
.ps-bg-greyscale-2 {
  background-color: $--color-greyscale-200;
}
.ps-bg-greyscale-3 {
  background-color: $--color-greyscale-300;
}
.ps-bg-default,
.ps-bg-greyscale-4 {
  background-color: $--color-greyscale-400;
}
.ps-bg-greyscale-5 {
  background-color: $--color-greyscale-500;
}
.ps-bg-greyscale-6 {
  background-color: $--color-greyscale-600;
}

/**
 * Fix pass image size
 * TODO: wait for support in vue 3.0
 */
.img-fluid {
  img {
    @include img-fluid;
  }
}
// Width and height
.mw-100 {
  img {
    max-width: 100% !important;
  }
}
.mh-100 {
  img {
    max-height: 100% !important;
  }
}

// Viewport additional helpers
.min-vw-100 {
  img {
    min-width: 100vw !important;
  }
}
.min-vh-100 {
  img {
    min-height: 100vh !important;
  }
}
.vw-100 {
  img {
    width: 100vw !important;
  }
}
.vh-100 {
  img {
    height: 100vh !important;
  }
}

/**
 * Font Sizing
 */
.p-black {
  font-size: 24px;
  font-weight: 900;
}

.p-med {
  font-size: 20px;
  font-weight: 500;
}

/**
 * Spacing
 */
.space-top-1 {
  margin-top: rem(24px);

  @include media-breakpoint-up(md) {
    margin-top: rem(40px);
  }
}

.space-bottom-1 {
  margin-bottom: rem(24px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(40px);
  }
}

/**
* Special cases
*/
.opened-hamburger {
  overflow: hidden;
  @include media-breakpoint-up('md') {
    // NOTE : Fix for dropdown menu is broken
    position: relative;
    z-index: -1;
  }
}
.opened-chat {
  overflow: hidden;
}

.middle-center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
