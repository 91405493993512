@include block('game-detail-spec') {
  margin-bottom: rem(16px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(32px);
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: rem(40px);
  }

  @include element('content') {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: rem(16px);
    padding-bottom: rem(16px);

    @include media-breakpoint-up(md) {
      padding-top: rem(28px);
      padding-bottom: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      padding-top: rem(32px);
      padding-bottom: rem(16px);
    }
  }

  @include element('title') {
    color: $--color-greyscale-300;
    font-size: rem(20px);
    line-height: 1;
    text-align: center;
  }

  @include element('value') {
    margin-top: 0;
    margin-bottom: 0;
    color: $--color-greyscale-600;
    font-size: rem(29px);
    font-weight: 500;
    line-height: 1;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: rem(34px);
    }
  }

  @include element('line') {
    margin: 0;
    opacity: 1;
    background-color: $--color-greyscale-200;
  }
}
