@include block('leaderboard-header') {
  height: rem(88px);

  @include media-breakpoint-up(lg) {
    height: rem(114px);
  }

  @include element('title') {
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    height: 100%;
    line-height: 1;

    @include media-breakpoint-up(md) {
      padding-top: rem(24px);
      padding-bottom: rem(24px);
    }
  }

  @include element('refresh') {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    width: rem(136px);
    height: 100%;

    @include media-breakpoint-up(md) {
      padding-top: rem(24px);
      padding-bottom: rem(24px);
    }
  }

  @include element('btn') {
    @include media-breakpoint-up(md) {
      padding-top: rem(10px);
      padding-bottom: rem(10px);
    }
  }

  @include element('info') {
    margin-top: rem(4px);
    color: $--color-greyscale-500;
    font-size: rem(16px);
    line-height: 1;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: rem(18px);
    }
  }
}
