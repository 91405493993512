@import 'libs/uikit/src/styles/global';

.container {
  position: sticky;
  top: 0;
  z-index: 100;
  // decrease from 1001 to 999 because can't click modal backdrop by Q
  // decrease from 999 to 98 because it was above generic modal (99) by Win
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  box-shadow: var(--box-shadow-1);
  padding-inline: 16px !important;

  &.single-logo {
    @include media('>lg') {
      grid-template-columns: auto;
    }
  }

  &.paged-container {
    grid-template-columns: repeat(3, 1fr);

    @include media('>lg') {
      grid-template-columns: auto 1fr;
    }
  }

  .logo-container {
    position: relative;
    width: min-content;
  }
  .logo-decor {
    position: absolute;
  }

  .loginButton {
    // TODO remove important in uikit; this !important is just temporary.
    // Due to update antd. and ant-btn replaced all default btn properties, so remove this !important
    height: 36px !important;
    width: 132px;
    padding: 0 !important;
  }

  .back-icon {
    @include media('>lg') {
      display: none;
    }

    &.hide {
      opacity: 0;
    }
  }

  .logo {
    cursor: pointer;
    margin: auto;
  }

  .chat-button {
    line-height: 0;
    display: flex;
    position: relative;
    margin: 0 4px 0 12px;
    flex-direction: column;
    align-items: center;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
  .wallet-button {
    font-weight: 400;
  }
  .menu-with-margin {
    margin-left: rem(10px);
    margin-right: rem(10px);
  }

  .menus {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0px;
    margin-right: -8px;

    .hamburger-button {
      position: relative;
      display: flex;
      margin: 0px 8px 0px 2px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .notification-badge {
        top: 50%;
        left: 50%;
        transform: translate(8px, -16px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--color-red);
        border: 2px solid var(--color-greyscale-100);
        position: absolute;
        z-index: 12;
      }
      .hamburger-icon {
        position: relative;
        width: 24px;
        height: 24px;
        margin: 10px 0px 10px 10px;
        user-select: none;
        .line {
          position: absolute;
          left: 0%;
          height: 2px;
          width: 90%;
          transform-origin: center;
          transition: 0.3s;
          background-color: var(--color-greyscale-600);
        }
        .line-1 {
          @extend .line;
          top: 10%;
          &.active {
            background-color: var(--color-violet-400);
            top: calc(50% - 1px);
            transform: rotate(45deg);
            width: 100%;
          }
        }
        .line-2 {
          @extend .line;
          top: calc(50% - 1px);
          &.active {
            opacity: 0;
          }
        }
        .line-3 {
          @extend .line;
          top: calc(90% - 2px);
          &.active {
            background-color: var(--color-violet-400);
            top: calc(50% - 1px);
            transform: rotate(-45deg);
            width: 100%;
          }
        }
      }
    }

    .link {
      color: var(--color-black);
      font-size: $--font-size-base-desktop;
    }

    .menu-mobile {
      display: none;

      @include media('>md') {
        display: flex;
      }
    }

    .menu-non-paged {
      display: none;

      @include media('>md') {
        display: flex;
      }
    }

    .menu-desktop {
      display: flex;
      @include media('<md') {
        display: none;
      }

      &.hide-menu-desktop-on-non-paged {
        display: flex;
        @include media('<lg') {
          display: none;
        }
      }
    }
  }

  .drawer-close {
    color: var(--color-primary-1);
  }
}

.root-drawer {
  &:global(.ant-drawer) {
    top: 63px; // header height
    z-index: 100;
    outline: none;

    :global(.ant-drawer-content-wrapper) {
      width: 360px !important;
      @include media('<370px') {
        width: 100% !important;
      }
    }

    .drawer {
      border-radius: 0px;

      :global(.ant-drawer-body) {
        padding: unset;
      }
    }
  }
}
