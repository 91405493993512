@include block('alert') {
  width: 100%;
  height: auto;
  padding: rem(16px);
  background-color: $--color-greyscale-100;
  box-shadow: 0 4px 8px rgba(42, 42, 46, 0.15);
  border-radius: rem(4px);
  display: flex;
  align-items: flex-start;
  gap: rem(10px);

  @include element('content-wrapper') {
    flex-grow: 1;
  }

  @include element('header') {
    display: flex;
    align-items: center;
    margin-bottom: rem(8px);
    height: auto;
    min-height: rem(24px);
    color: $--color-greyscale-600;
    font-size: rem(24px);
    font-weight: bold;
    line-height: 1;

    .ps-button__body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ps-icon-info,
    .ps-icon-check-stroke,
    .ps-icon-close {
      height: rem(24px);
    }

    .ps-icon-info::before,
    .ps-icon-check-stroke::before,
    .ps-icon-close::before {
      display: block;
      font-size: rem(15px);
    }

    .ps-icon-info::before,
    .ps-icon-check-stroke::before {
      margin-right: rem(8px);
    }

    .ps-icon-info::before {
      color: $--color-red;
    }

    .ps-icon-check-stroke::before {
      color: $--color-green-200;
    }

    .ps-icon-close::before {
      color: $--color-greyscale-400;
    }
  }

  @include element('body') {
    margin-top: rem(4px);
    margin-bottom: rem(8px);
    font-size: rem(20px);
    line-height: rem(20px);

    > * {
      margin: 0;
      color: $--color-greyscale-600;

      line-height: 1;
    }

    a {
      text-decoration: underline !important;
    }

    @include modifier('extra-margin-with-icon') {
      margin-left: rem(23px);
    }

    @include modifier('no-header') {
      align-items: flex-start;

      & > .ps-alert__icon--error {
        margin-top: rem(8px);
      }
    }
  }

  @include element('footer') {
    margin-top: rem(4px);

    button.ps-button {
      font-weight: 400 !important;
      padding: 0 !important;
    }
  }

  @include element('icon') {
    margin-right: rem(8px);
    font-size: rem(14px);

    @include modifier('success') {
      color: $--color-green-200;
    }

    @include modifier('error') {
      color: $--color-red-200;
    }
  }

  @include element('close') {
    font-size: rem(16px);
  }
}

.ant-modal-content {
  padding: 0 !important;
}
