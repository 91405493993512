@include block('new-game-card') {
  margin-right: 0;
  cursor: pointer;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-right: 0;
    margin-bottom: rem(24px);
    width: 100%;
  }

  @include element('cover') {
    border-radius: rem(8px);
  }

  @include element('star-icon-container') {
    height: rem(44px);
    width: rem(44px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    .ps-icon-star-filled {
      color: $--color-greyscale-100;
    }
  }

  @include element('top-layer') {
    border-radius: rem(8px);
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  @include element('footer-wrap') {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: rem(10px 0);

    @include element('title') {
      display: block;
      margin: 0;
      flex-shrink: 1;
      color: $--color-greyscale-600;
      line-height: 1;
      font-size: rem(20px);
      font-weight: bold;
      float: left;
    }

    @include element('play-btn') {
      display: block;
      font-size: rem(20px);
      line-height: 1;
      flex-shrink: 1;
      padding: rem(8px 16px);
      background-color: $--color-orange-300;
      min-width: rem(73.36px);

      .ps-button__body {
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}

.ps-big-win__notifier {
  line-height: rem(22px);
}
