@import 'libs/uikit/src/styles/global';

.highlight {
  &.primary {
    color: var(--color-primary-1);
  }
  &.danger {
    color: var(--color-danger);
  }
  &.black {
    color: var(--color-black);
  }
}
