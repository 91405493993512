@import 'libs/uikit/src/styles/global';

.container {
  &:global(.ant-notification-notice) {
    :global(.ant-notification-notice-with-icon) {
      margin-top: -1px;

      :global(.ant-notification-notice-message) {
        margin-bottom: 0px;
        font-weight: normal;
        font-size: rem(20px);
        font-family: $font-family;
      }
    }
  }

  &.info-container {
    border: 2px solid #5bc8ec; // color-lightblue-200
    background-color: #e6f7fc; // color-lightblue-100
  }

  &.warning-container {
    border: 2px solid #ffb24d; // color-orange-100
    background-color: #fff4e6; // color-orange-300
  }

  // IMPROVE: move notification into next-root for receiving class variables
  .icon {
    &.info {
      color: #5bc8ec; // color-lightblue-200
    }

    &.success {
      color: #408558; // color-green-200
    }

    &.error {
      color: #c8373e;
    }

    &.warning {
      color: #ff9100;
    }
  }
}
