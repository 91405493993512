@include block('input-digit') {
  @include element('label') {
    color: $--color-greyscale-500;
    text-align: center;
    font-size: rem(20px);
    font-weight: 400;
  }
  @include element('dot') {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include element('input') {
    display: flex;
    justify-content: center;

    div:first-child > input {
      margin-left: 0;
      margin-right: rem(4px);
    }

    div:last-child > input {
      margin-left: rem(4px);
      margin-right: 0;
    }

    &-item {
      font-size: rem(32px);
      border: 1px solid $--color-white-200;
      caret-color: $--color-greyscale-400;
      color: $--color-greyscale-600;
      border-radius: rem(8px);
      margin: rem(0px 4px);
      text-align: center;
      outline: none;

      width: rem(42px);
      height: rem(49px);

      @include media-breakpoint-up('sm') {
        width: rem(48px);
        height: rem(56px);
      }

      &:disabled {
        background-color: $--color-greyscale-200;
        border: none;
      }

      &:focus {
        border: rem(2px) solid $--color-violet-400;
        caret-color: $--color-violet-400;
      }

      @include modifier('error') {
        border: rem(2px) solid $--color-red-200;
      }
    }
  }
  @include element('alert') {
    color: $--color-red-200;
    text-align: center;
    font-weight: 400;
    font-size: rem(20px);
    margin-top: rem(4px);
    height: rem(0px);
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      font-size: rem(15px);
      margin: rem(0px 4px);
      line-height: rem(0px);
    }
  }
}
