@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  width: auto;
  gap: 10px;
  transform-origin: 0px 0px;
  margin-right: -9px;
  @keyframes antFloatButtonMoveDownIn {
    0% {
      transform: translate3d(50px, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes antFloatButtonMoveDownOut {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(50px, 0, 0);
      opacity: 0;
    }
  }
  :global(.ant-float-btn-group-wrap-enter) {
    animation: antFloatButtonMoveDownIn 0.3s forwards !important;
  }
  :global(.ant-float-btn-group-wrap-leave) {
    animation: antFloatButtonMoveDownOut 0.3s forwards !important;
  }

  :global(.ant-float-btn-group-wrap) {
    display: flex;
    gap: 10px;
    margin-bottom: unset;
  }

  & > :global(.ant-float-btn-circle) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-orange-400);
    width: 50px;
    height: 50px;

    :global(.ant-badge) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :global(.ant-float-btn-body) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 24px;
      height: 24px;
      background-color: var(--color-orange-400);

      &:hover {
        background-color: var(--color-orange-400);
      }

      :global(.ant-float-btn-content) {
        width: 24px;
        height: 24px;
        min-height: unset;
        padding: unset;
        overflow: unset;

        :global(.ant-float-btn-icon) {
          font-size: unset;
          line-height: unset;
          width: 24px;
          height: 24px;

          svg {
            margin-top: -10px;
            color: var(--color-white);
          }
        }
      }
    }
  }
}

.float-btn {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:global(.ant-btn-icon-only) {
    width: 50px;
  }

  :global(.ant-badge) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.ant-float-btn-body) {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.hide {
    // REMOVE: remove me if find the problem of floating-button has blinking while closing floating-button
    opacity: 0;
    animation: hide 0.2s linear;

    @keyframes hide {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  .icon {
    color: var(--color-white);
    fill: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }

    path {
      color: var(--color-white);
      fill: var(--color-white);
    }
  }
}
