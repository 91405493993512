.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .round {
    border-radius: 50%;
  }

  .vip-tag {
    margin-top: -5px;
  }
}
