/**
* Base font family
*/
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Li.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Med.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Bd.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Blk.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

/**
 * Icons
 */
@font-face {
  font-family: 'ps-icons';
  src: url('/fonts/ps-icons/ps-icons.eot');
  src: url('/fonts/ps-icons/ps-icons.eot?#iefix') format('embedded-opentype'),
    url('/fonts/ps-icons/ps-icons.woff') format('woff'),
    url('/fonts/ps-icons/ps-icons.ttf') format('truetype'),
    url('/fonts/ps-icons/ps-icons.svg') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
