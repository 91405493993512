@include block('game-icon') {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  @include element('company-frame') {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    > div,
    span {
      margin-top: -13px !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 22%;
    }
  }

  @include element('cover') {
    border-radius: 22%;

    @include modifier('top-layer') {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

@include block('new-game-icon-frame') {
  position: absolute;
  margin-top: calc(-100% - 12px);
  left: 0%;
  z-index: 11;

  @include element('icon') {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    font-weight: 500;
    padding: 0px 6px;
    background-color: $--color-greyscale-600;
    color: $--color-greyscale-100;
    border-radius: 6px;
    border-radius: 62px 32px 32px 0px;
  }
}
