@import 'libs/uikit/src/styles/global';

.no-cancel {
  display: flex;
  justify-content: flex-end;
  margin-top: 50;
  color: var(--color-primary-1);
}

.modal-method {
  :global(.ant-modal-content) {
    border-radius: 4px;
    font-family: 'db-heavent';

    :global(.ant-modal-confirm-title) {
      font-weight: normal;
      font-family: 'db-heavent';
      font-size: rem(24px);
    }
  }

  :global(.ant-modal-confirm-body) {
    & > :global(.anticon) {
      font-size: rem(18px);
    }

    :global(.ant-modal-confirm-content) {
      font-size: rem(20px);
    }
  }

  .cancel-button {
    font-family: 'db-heavent';
    font-size: rem(24px);
    font-weight: 500;
    color: var(--color-greyscale-400);
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:not(.ant-btn-disabled) {
      &:hover,
      &:focus,
      &:visited {
        color: var(--color-greyscale-400);
        outline: none;
        border: none;
      }
    }
  }

  .ok-button {
    font-family: 'db-heavent';
    font-size: rem(24px);
    font-weight: 500;
    color: var(--color-primary-1);
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:not(.ant-btn-disabled) {
      &:hover,
      &:active,
      &:focus,
      &:visited {
        color: var(--color-primary-1);
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }
}

.modal-image {
  :global(.ant-modal-body) {
    overflow: inherit;
    padding: 16px;

    :global(.ant-modal-confirm-title) {
      overflow: inherit;

      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -124px;
        font-size: rem(20px);
      }
    }
  }
}
