@include block('winner-tag') {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  @include element('title') {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $--color-violet-200;
    border-radius: rem(20px);
    width: rem(84px);
    height: rem(20px);
    color: $--color-greyscale-100;
    font-size: rem(16px);
    line-height: 1;
    background-color: $--color-violet-200;
    z-index: 1;

    @include media-breakpoint-up(md) {
      width: rem(100px);
      height: rem(24px);
      font-size: rem(18px);
    }
  }

  @include element('reward') {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(-4px);
    border: 1px solid $--color-violet-200;
    border-radius: rem(20px);
    width: rem(76px);
    height: rem(32px);
    color: $--color-violet-200;
    font-size: rem(24px);
    line-height: 1;
    background-color: $--color-greyscale-100;

    @include media-breakpoint-up(md) {
      width: rem(90.48px);
      height: rem(36px);
    }
  }

  @include modifier('active') {
    @include element('title') {
      border-color: $--color-violet-300;
      background-color: $--color-violet-300;
    }

    @include element('reward') {
      border-color: $--color-violet-300;
      color: $--color-violet-300;
    }
  }
}
