@include block('marquee-card') {
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  height: rem(100px);
  border-radius: $--border-radius-1;
  background-color: $--color-greyscale-500;
  box-shadow: $--box-shadow-1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: rem(0px);
  @include media-breakpoint-only('lg') {
    width: 100%;
    max-width: 350px;
    height: rem(150px);
    padding: rem(24px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  @include media-breakpoint-up('xl') {
    width: 100%;
    max-width: 570px;
    height: rem(200px);
    padding: rem(24px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  @include element('bg-image') {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  @include element('btn') {
    position: relative;
    z-index: 2;
    display: block;
    font-weight: bold;
  }
}
@include block('game-card') {
  margin-bottom: rem(16px);
  padding-bottom: rem(16px);
  border-bottom: 0.5px solid #dedee0;
  cursor: pointer;
  @include modifier('lobby') {
    margin-right: rem(16px);
    @include media-breakpoint-up(md) {
      //   padding-bottom: 10px !important;
    }
  }
  @include element('disable-card') {
    cursor: default;
  }
  @include modifier('rtp-trend') {
    background-color: rgba(255, 0, 0, 0.168);
  }
  @include element('no-img') {
    width: 100%;
    @include media-breakpoint-up(lg) {
      height: rem(227px);
    }
    @include media-breakpoint-up(lg) {
      height: rem(248px);
    }
  }
  @include element('img') {
    width: rem(86px);
    height: rem(86px);
  }
  @include element('search-no-img') {
    width: 100%;
    @include media-breakpoint-up(md) {
      height: rem(167px);
    }
    @include media-breakpoint-up(lg) {
      height: rem(227px);
    }
    @include media-breakpoint-up(lg) {
      height: rem(248px);
    }
  }
  @include media-breakpoint-up(lg) {
    margin-right: 0;
    width: 100%;
  }

  @include element('image-container') {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  @include element('cover') {
    border-radius: rem(8px);
    @include modifier('top-layer') {
      position: absolute;
    }
  }
  @include element('image') {
    display: block;
  }
  @include element('btn') {
    margin-top: rem(8px);
    font-size: rem(20px);
    line-height: 1;
    .ps-button__body {
      padding: 0 !important;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: rem(8px);
    }
  }

  @include element('btn-lobby') {
    margin-top: rem(16px);
    font-size: rem(20px);
    line-height: 1;
    padding: 8px 31px;
    .ps-button__body {
      padding: 0 !important;
    }
    height: 5ch;
    @include media-breakpoint-up(lg) {
      margin-top: rem(8px);
    }
  }

  @include element('card-container') {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 100%;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      height: rem(88px);
    }
  }
  @include element('card-container-lobby') {
    display: flex;
    position: relative;
    margin-left: rem(7px);
    flex-direction: column;
    height: 100%;
    width: 100%;
    @include media-breakpoint-up(lg) {
      height: rem(88px);
    }
  }
  @include element('text-container') {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include media-breakpoint-up(md) {
      height: rem(55px);
      justify-content: flex-start;
    }
    @include media-breakpoint-up(lg) {
      justify-content: center;
      height: 100%;
    }
  }
  @include element('text-container-lobby') {
    display: flex;
    justify-content: space-between;
  }
  @include element('btn-container-lobby') {
    display: flex;
    justify-content: flex-start;
    align-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-up(xs) {
      justify-content: space-between;
    }
  }

  @include element('star-icon-container') {
    height: rem(44px);
    width: rem(44px);
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: 0;
  }

  @include element('text') {
    display: flex;
    flex-wrap: wrap;
    max-width: 55%;
    margin: 0;
    color: $--color-greyscale-600;
    font-size: rem(20px);
    line-height: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include modifier('hide') {
      opacity: 0;
      font-size: rem(14px);
    }
    @include modifier('title') {
      font-weight: bold;
      line-height: rem(16px);
      @include media-breakpoint-up(lg) {
        margin-top: rem(8px);
      }
    }
    @include element('sub-text') {
      width: 100%;
    }
    @include element('rtp') {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include media-breakpoint-up(xs) {
        flex-wrap: nowrap;
      }
      @include modifier('hot') {
        @include element('rtp-dot') {
          background-color: #e73812 !important;
        }
        @include element('rtp-bar') {
          background-color: #e73812 !important;
        }
      }
      @include element('rtp-dot') {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        margin: 4px;
        background-color: $--color-blue-200;
      }
      @include element('rtp-bar') {
        display: inline-block;
        font-size: 16px;
        color: $--color-greyscale-100;
        background-color: $--color-blue-200;
        font-weight: 400;
        border-radius: 40px;
        line-height: 2.5ch;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        padding: 0 0.5ch;
        @include media-breakpoint-up(xs) {
          width: 9ch;
          padding: auto;
        }
      }
      @include element('rtp-rate') {
        color: $--color-greyscale-200;
        margin-left: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 2.5ch;
        display: none;
        @include media-breakpoint-up(xs) {
          display: inline-block;
        }
      }
    }
  }
  @include element('text-lobby') {
    margin-top: 0;
    margin-bottom: 0;
    color: $--color-greyscale-600;
    font-size: rem(20px);
    line-height: 1;
    width: rem(95px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include media-breakpoint-up(lg) {
      width: rem(135px);
    }
    @include modifier('title') {
      font-weight: bold;
      line-height: 28px;
      letter-spacing: 0.04ch;
      width: 78%;
      min-width: 0px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include media-breakpoint-up(lg) {
        margin-top: rem(8px);
      }
    }
  }
}

@include block('card') {
  border-radius: $--border-radius-1;
  width: fit-content;
  height: fit-content;
  box-shadow: $--box-shadow-1;
  overflow: hidden;
}

@include block('wallet-card') {
  cursor: pointer;
  position: relative;
  height: rem(154px);
  width: rem(272px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(16px);
  color: $--color-greyscale-100;
  line-height: 1;
  background: radial-gradient(
      35.91% 49.24% at 89.24% 94.87%,
      rgba(49, 0, 119, 0.5) 0%,
      rgba(78, 0, 190, 0) 100%
    ),
    linear-gradient(
      105.25deg,
      rgba(48, 0, 119, 0.3) 1.51%,
      rgba(208, 179, 250, 0.3) 100%
    ),
    radial-gradient(
      96.15% 159.76% at 53.13% 3.85%,
      rgba(33, 210, 254, 0.5) 0%,
      rgba(33, 210, 254, 0) 100%
    ),
    linear-gradient(90deg, #310077 0%, #914df3 100%);
  &:hover {
    filter: contrast(1.2);
    transition: 0.2s;
  }
  @include media-breakpoint-up('md') {
    height: rem(273px);
    width: rem(484px);
  }
  @include media-breakpoint-up('lg') {
    height: rem(194px);
    width: rem(344px);
  }
  @include modifier('bonus') {
    color: $--color-greyscale-600;
    background: linear-gradient(
        175.72deg,
        rgba(255, 218, 22, 0.375) 6.89%,
        rgba(255, 218, 22, 0) 99.92%
      ),
      linear-gradient(
        295.94deg,
        rgba(255, 145, 0, 0.5) 0%,
        rgba(246, 194, 68, 0.5) 0.01%,
        rgba(204, 116, 0, 0.1) 54.77%
      ),
      radial-gradient(
        101.16% 119.54% at 65.63% 0%,
        rgba(255, 218, 22, 0.5) 0%,
        rgba(255, 218, 22, 0) 100%
      ),
      linear-gradient(90deg, #ff9100 0%, rgba(255, 145, 0, 0) 100%);
  }
  @include element('header') {
    display: flex;
    flex-direction: column;
  }
  @include element('footer') {
    display: flex;
    align-items: center;
  }
  @include element('time') {
    color: $--color-greyscale-100;
    line-height: 1;
    font-size: rem(20px);
    font-weight: normal;
  }
  @include element('text') {
    color: $--color-greyscale-100;
    line-height: 1;
    font-size: rem(24px);
    font-weight: normal;
  }
  @include element('amount') {
    color: $--color-greyscale-100;
    line-height: 1;
    font-weight: normal;
    font-size: rem(42px);
  }
  @include element('phone-number') {
    opacity: 0.6;
    font-size: 20px;
  }
  @include element('amount-spin') {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
  }
  @include element('icon-container') {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(20px);
    width: rem(20px);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    margin-right: 4px;
  }

  @include element('icon') {
    max-width: 11px;
    max-height: 11px;
  }
  @include element('active') {
    animation: spin-animation 0.2s infinite;
    display: inline-block;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  @include element('text-bonus') {
    color: $--color-greyscale-600 !important;
  }
}
