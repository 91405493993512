@include block('marquee') {
  margin-bottom: 8px;
  @include element('headline') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em auto;
    @include media-breakpoint-up('lg') {
      padding-top: 2em;
    }
  }
  @include element('title') {
    line-height: 0.8em;
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 8px;
    @include media-breakpoint-up('lg') {
      line-height: rem(95.94px);
    }
  }
  @include element('subtitle') {
    font-size: rem(34.56px);
    line-height: rem(34.56px);
    text-align: center;
    @include media-breakpoint-up('lg') {
      font-size: rem(47.99px);
      line-height: rem(47.99px);
      text-align: center;
    }
  }
  @include element('img-logo') {
    width: 150px;
    height: 32px;
    margin-bottom: 0.5em;
    @include media-breakpoint-up('md') {
      width: 300px;
      height: 64px;
    }
  }
  @include element('btn-bold') {
    font-weight: bold;
  }
  @include element('marquee-container') {
    margin-top: 8px;
    width: 100%;
    height: 440px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: $--color-greyscale-200;
    @include media-breakpoint-up('md') {
      width: 100%;
      height: 432px;
    }
    @include media-breakpoint-up('lg') {
      width: 100%;
      height: 576px;
    }
    @include media-breakpoint-up('xl') {
      width: 100%;
      height: calc(100vh - 64px);
    }
  }
  @include element('bg-image-mb') {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  @include element('bg-vdo-desktop') {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-only('md') {
      width: 100%;
    }
    @include media-breakpoint-only('lg') {
      width: 100%;
    }
    @include media-breakpoint-only('xl') {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include element('content-container') {
    z-index: 80;
    padding: 12px;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-only('md') {
      padding: 28px;
    }
    @include media-breakpoint-up('lg') {
      padding: 68px;
    }
  }
  @include element('item') {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up('md') {
      justify-content: space-between;
      align-items: center;
    }
  }
  @include element('item-style') {
    color: $--color-greyscale-100;
    display: none;
    @include media-breakpoint-up('md') {
      display: block;
      padding-top: 10px;
    }
  }
}
