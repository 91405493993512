@include block('modal') {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba($--color-greyscale-600, 0.5);
  z-index: 102;

  @include modifier('open') {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include element('wrapper') {
    min-width: rem(100px);
    min-height: rem(100px);
  }
}
