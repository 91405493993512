@include block('article') {
  margin-bottom: rem(16px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(32px);
  }

  @include element('logo') {
    margin-bottom: rem(32px);

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }
  @include element('logo-container') {
    width: rem(156px);
    height: rem(156px);
  }

  @include element('title') {
    margin-bottom: rem(16px);
    margin-top: rem(20px);
    font-weight: 900;
    line-height: 1;

    a {
      text-decoration: underline;
      font-weight: 900;
      &:visited {
        text-decoration: none;
        opacity: 0.42;
      }
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
      &:active {
        opacity: 1;
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: rem(8px);
      font-size: rem(48px);
    }
  }
  @include element('titlemtop') {
    margin-bottom: rem(16px);
    margin-top: rem(40px);
    font-weight: 900;
    line-height: 1;

    a {
      text-decoration: underline;
      font-size: rem(33px);
      &:visited {
        text-decoration: none;
        opacity: 0.42;
      }
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
      &:active {
        opacity: 1;
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: rem(8px);
      font-size: rem(48px);
    }
  }

  @include element('wrapper') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: rem(16px);
    line-height: 1.2;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }

  @include element('article_wrapper') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: rem(16px);
    line-height: 1.2;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }

  @include element('content') {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;

    h,
    img,
    span {
      margin-top: rem(20px);
    }

    a {
      text-decoration: underline;
      font-size: 1em;
      &:visited {
        text-decoration: none;
        opacity: 0.42;
      }
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
      &:active {
        opacity: 1;
        text-decoration: none;
      }
    }

    p {
      display: inline;
      line-height: 1.2;
      word-break: break-word;
      padding-left: 2em;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);
      }
    }
  }

  @include element('contentmtop') {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: rem(20px);

    a {
      text-decoration: underline;
      font-size: 1em;
      &:visited {
        text-decoration: none;
        opacity: 0.42;
      }
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
      &:active {
        opacity: 1;
        text-decoration: none;
      }
    }

    h,
    img,
    span {
      margin-top: rem(20px);
    }

    p {
      display: inline;
      line-height: 1.2;
      word-break: break-word;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);
      }
    }
  }

  @include element('article') {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      display: inline;
      line-height: 1.2;
      word-break: break-word;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);
      }
    }
  }

  @include element('btn') {
    display: inline-block;
    margin-top: rem(8px);
    padding-right: 0;
    padding-left: 0;
    font-weight: 400;

    span {
      text-decoration: underline;
      text-underline-offset: 1px;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  @include element('line') {
    margin: 0;
    opacity: 1;
    background-color: $--color-greyscale-200;
  }

  @include modifier('expanded') {
    @include element('content') {
      -webkit-line-clamp: initial;

      p {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);

        @include media-breakpoint-up(md) {
          margin-bottom: rem(32px);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
