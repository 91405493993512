@import 'libs/uikit/src/styles/global';

/*datepicker*/
.ant-picker {
  font-family: $font-family;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $--color-greyscale-500;
  font-size: rem(24px);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  padding: rem(4px 11px 4px);
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  min-width: rem(117px);
  height: rem(44px);
  border: rem(2px) solid $--color-greyscale-400;
  border-radius: rem(4px);
  transition: border 0.3s, box-shadow 0.3s;
}

.ant-picker-input > input {
  font-size: rem(24px) !important;
}

.ant-picker-footer {
  display: none;
}

.ant-picker-today-btn {
  color: $--color-primary-1;

  &:hover {
    color: $--color-primary-1;
  }
}

.ant-picker-dropdown {
  font-family: $font-family;
  font-size: 20px;
  color: $--color-greyscale-600;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $--color-primary-1;
  border-right-width: rem(2px) !important;
  box-shadow: none !important;
}

.ant-picker.ant-picker-disabled {
  background: $--color-greyscale-200;
  border-color: $--color-greyscale-200 !important;
  cursor: not-allowed;
}

.ant-picker.ant-picker-disabled:active,
.ant-picker-disabled:hover,
.ant-picker-disabled {
  background: $--color-greyscale-200;
  border-color: $--color-greyscale-200 !important;
  cursor: not-allowed;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $--color-primary-1 !important;
  border-radius: rem(16px);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: rem(1px) solid $--color-primary-1;
  border-radius: rem(2px);
  content: '';
  border-radius: rem(16px);
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;

  &:hover {
    color: $--color-primary-1;
  }
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: rem(32px);
  height: rem(32px);
  line-height: rem(32px);
  border-radius: rem(16px);
  transition: background 0.3s, border 0.3s;
}

.ant-picker-date-panel .ant-picker-body {
  padding: rem(16px 24px);
}

.ant-picker-dropdown {
  width: rem(300px);
}

.ant-picker-date-panel {
  width: 100%;
}

.ant-picker-year-panel {
  width: 100%;
}

.ant-picker-month-panel {
  width: 100%;
}

/*checkbox*/
.ant-checkbox-inner {
  width: rem(24px);
  height: rem(24px);
  border: rem(2px) solid $--color-greyscale-600;
  border-radius: rem(4px);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $--color-primary-1;
  border-color: $--color-primary-1;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $--color-primary-1;
}

.ant-checkbox-checked::after {
  border: rem(1px) solid $--color-primary-1;
  border-radius: rem(4px);
}

.ant-checkbox-inner::after {
  top: 43%;
  left: 17.49%;
  width: rem(8px);
  height: rem(16px);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: rem(3px) solid #fff;
  border-radius: rem(1px);
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

/* tooltips */
.ant-tooltip {
  max-width: initial !important;
}

.ant-tooltip-inner {
  padding: 0px !important;
  background: none !important;
  box-shadow: none !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

/* spin */
.ant-spin-dot-item {
  background-color: $--color-primary-1;
}

.ant-spin {
  color: $--color-violet-200;
}

/* tabs */
.ps-tabs {
  padding-bottom: rem(24px);

  .ant-tabs-nav {
    margin: 0 0 rem(24px) 0 !important;

    &::before {
      border: none !important;
    }
  }

  .ant-tabs-nav-list {
    color: $--color-greyscale-600;

    .ant-tabs-tab {
      font-size: rem(24px);
      line-height: rem(24px);
      padding: 0 0 rem(4px) 0;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: rem(2px) solid $--color-greyscale-200;
        bottom: 0;
        left: 0;
      }

      &:first-child::before {
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
      }

      &:nth-last-child(2)::before {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
      }

      &:not(:first-child) {
        &::before {
          width: calc(100% + 16px);
          left: rem(-16px);
        }

        margin-left: rem(16px);
      }

      .ant-tabs-tab-btn {
        &:hover,
        &:focus,
        &:active {
          color: $--color-greyscale-600;
        }
      }
    }

    .ant-tabs-tab-active {
      &:hover,
      &:focus,
      &:active,
      .ant-tabs-tab-btn {
        color: $--color-primary-1 !important;
      }
    }

    .ant-tabs-ink-bar {
      background: $--color-primary-1;
      border-radius: 50px;
    }
  }

  .ant-tabs-tabpane {
    font-size: rem(20px);
    line-height: rem(20px);
    height: auto !important;
    max-height: 100%;
    transition: max-height 0.3s ease, visibility 0.3s ease;

    &:not(.ant-tabs-tabpane-active) {
      max-height: 0;
      padding: 0 0.75rem;
      visibility: hidden !important;
    }
  }
}

.ant-popover {
  z-index: 80;
}

.ant-input {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;

  color: $--color-greyscale-600;
}

.ant-input-affix-wrapper {
  width: 100%;
  height: 44px;
  display: flex;
  border: 2px solid $--color-greyscale-400;
  border-radius: 4px;
  overflow: hidden;
  align-content: center;
  align-items: center;

  &.ant-input {
    width: 100%;
    height: auto;
    border: none;
    border-radius: 0px;
    overflow: hidden;
  }

  .ant-input-prefix {
    color: $--color-greyscale-300;
    font-size: 4ch;
  }
}

input {
  &.ant-input {
    font-style: normal;
    width: 100%;
    height: 44px;
    display: flex;
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    overflow: hidden;
  }
}

textarea {
  &.ant-input {
    font-style: normal;
    width: 100%;
    height: 44px;
    display: flex;
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    overflow: hidden;
  }
}

/* Steps / Step */
.ant-steps {
  .ant-steps-item {
    padding: 0 !important;
  }

  .ant-steps-item-icon {
    margin: 0;
  }

  .ant-steps-item-title::after {
    left: 0;
    height: rem(3px);
  }
}

.ant-modal-content {
  border-radius: 4px;
  max-width: 90vw;
  margin: auto;

  .ant-modal-header {
    border: none;
    padding-bottom: 0px;

    .ant-modal-title {
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 28px;
      color: $--color-greyscale-600;
    }
  }

  .ant-modal-body {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: $--color-greyscale-600;
    padding: 20px;

    b {
      color: $--color-primary-1;
      padding-left: 0.7ch;
      padding-right: 0.7ch;
      font-weight: inherit;
      font-weight: 500;
    }
  }

  .ant-modal-footer {
    border: none;

    .ant-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: $--color-greyscale-400;
      background-color: transparent;
      border: none;
      box-shadow: none;

      &.ant-btn-primary {
        color: $--color-primary-1;
      }
    }
  }
}
.ant-notification-top {
  inset: 72px auto auto 50% !important;
}

.ant-notification-notice {
  border-radius: 8px;

  .ant-notification-notice-message {
    padding-top: 2px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $--color-greyscale-600;
  }

  .ant-notification-notice-description {
    display: none;
  }

  &.ant-notification-notice-success {
    border: 2px solid $--color-green-200;
    background: $--color-green-100;
  }

  &.ant-notification-notice-error {
    border: 2px solid $--color-red-200;
    background: $--color-red-100;
  }
}

.ant-space-item {
  font-size: 24px;
}

.ant-dropdown-menu {
  border-radius: 4px;
}

.ant-dropdown-menu-title-content {
  font-size: 20px;
  min-width: 7ch;
  text-align: right;
}

.ant-scroll-number {
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Helvetica Neue', 'Arial';
  z-index: 20;
}

.ant-badge-count {
  box-shadow: 0 0 0 2px #efe6fd;
  background: #c8373e;
  .ant-badge-status-dot {
    background: #c8373e;
  }
}
