@include block('stepper-controller') {
  @include element('title') {
    margin-bottom: rem(8px);
    line-height: rem(42px);
    min-height: rem(42px);
    font-weight: 900;
  }
  @include element('back-icon') {
    position: absolute;
    margin-top: rem(-52px);
    width: 44px;
    height: 44px;
    cursor: pointer;
  }
  @include element('progress') {
    margin: 0px 0px rem(16px) 0px;
    padding: 0px rem(28px);
  }
  @include element('progress-desktop') {
    margin: 0px 0px rem(16px) 0px;
    padding: 0px rem(28px);
    height: rem(124px);

    .ps-stepper__step-subtitle {
      line-height: rem(20px);
    }
  }
}
