$--vip-bronze: linear-gradient(
  115.39deg,
  #b94520 21.73%,
  #8c3114 65.76%,
  #b94520 84.35%
);
$--vip-silver: linear-gradient(
  148.67deg,
  #a8a8a8 23.45%,
  rgba(69, 69, 69, 0.95) 59.17%,
  #a8a8a8 81.08%
);
$--vip-gold: linear-gradient(
  148.67deg,
  #eb8d00 23.45%,
  rgba(139, 84, 0, 0.95) 59.17%,
  #eb8d00 81.08%
);
$--vip-diamond: linear-gradient(
  148.67deg,
  #491db5 23.45%,
  rgba(43, 17, 107, 0.95) 59.17%,
  #491db5 81.08%
);

@include block('user-avatar') {
  display: inline-block;
  position: relative;
  padding: 0px;
  width: auto;
  @include element('avatar') {
    display: block;
  }
  @include element('guild') {
    position: absolute;
    top: 100%;
    right: -1px;
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: right;
    transform: translateY(-50%);
  }
}
@include block('user-tier') {
  display: flex;
  flex-direction: row;
  gap: 8px;
  user-select: none;
}
@include block('user-kyc') {
  display: flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: $--color-greyscale-500;
  margin: 12px 0px;
  text-decoration: none;

  @include element('button') {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  @include element('label') {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 30px;
    svg {
      margin-top: -1px;
    }
  }
}
@include block('user-badge') {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  width: 30px;
  height: 16px;
  padding: 1px 8px;
  margin-bottom: 2px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  user-select: none;
  z-index: 20;
  @include modifier('none') {
    background: #ffffff;
  }
  @include modifier('bronze') {
    background: $--vip-bronze;
  }
  @include modifier('silver') {
    background: $--vip-silver;
  }
  @include modifier('gold') {
    background: $--vip-gold;
  }
  @include modifier('diamond') {
    background: $--vip-diamond;
  }
}
