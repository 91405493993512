@include block('winner-stage') {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  @include element('title') {
    color: $--color-greyscale-500;
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1;
    margin-bottom: rem(8px);

    @include media-breakpoint-up(md) {
      font-size: rem(24px);
      margin-bottom: rem(16px);
    }
  }

  @include element('trophy') {
    margin-bottom: rem(8px);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(16px);
    }
  }

  @include modifier('active') {
    @include element('title') {
      color: $--color-violet-300;
      font-weight: 900;
    }
  }

  @include modifier('empty') {
    @include element('title') {
      color: $--color-greyscale-300;
      font-weight: 400;
    }
  }
}
