@include block('deposit-qr') {
  display: block;
  position: relative;
  width: 100%;

  @include modifier('disabled') {
    width: 100%;
    height: 100%;
    user-select: none;
    touch-action: manipulation;
    pointer-events: none;
    filter: opacity(0.1) blur(rem(2px));
  }

  @include element('motion') {
    display: flex;
    flex-direction: row;
  }

  @include element('loader') {
    .ps-loading {
      margin: rem(64px) auto;
    }
  }

  @include element('add-money-group') {
    margin-top: rem(8px);
    margin-bottom: rem(16px);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    column-gap: rem(8px);
    width: 100%;

    @include element('add-money-item') {
      flex: 1 1 0px;
    }

    @include media-breakpoint-up(md) {
      column-gap: rem(16px);
      margin-bottom: rem(24px);
    }
    @include media-breakpoint-up(lg) {
      column-gap: rem(24px);
    }
  }

  .ps-information-box {
    margin-bottom: rem(32px);
    font-weight: 500;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }
  }

  .ps-button {
    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }
  }

  @include element('title') {
    font-size: rem(20px);
    line-height: rem(20px);
    text-align: left;
    margin: 0;
  }
  @include element('snapshot-area') {
    padding-top: rem(8px);
    padding-bottom: rem(24px);
    padding-left: rem(4px);
    padding-right: rem(4px);

    @include element('qr-code-wrap') {
      position: relative;
      min-height: 160px;
      display: flex;
      align-items: center;
      margin: auto;

      > i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 300ms ease-out;
        cursor: pointer;
        filter: none;
        touch-action: manipulation;
        font-size: rem(16px);
      }

      @include element('qr-code') {
        position: relative;
        display: block;
        padding: 0;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
          height: rem(180px);
        }

        @include modifier('expired') {
          filter: opacity(0.1);
          cursor: pointer;
          transition: filter 300ms linear;

          &:hover {
            filter: opacity(0.25);
          }
        }
      }
    }

    @include element('info-data') {
      list-style: none;
      font-size: rem(24px);
      line-height: rem(24px);
      margin: 0;

      @include element('info-data-item') {
        margin-top: rem(4px);

        @include modifier('fade') {
          color: $--color-greyscale-300;
        }
        @include modifier('emphasize') {
          font-weight: 900;
        }
      }
    }
    @include element('footer-caption') {
      font-size: rem(20px);
      line-height: rem(20px);
      margin-top: rem(8px);
      margin-bottom: rem(4px);
    }
    @include element('footer-notice') {
      margin: 0;
      display: inline-flex;
      column-gap: rem(4px);
      font-size: rem(20px);
      line-height: rem(20px);
      font-weight: 900;
      white-space: nowrap;

      > i {
        font-size: rem(16px);
      }
    }
  }
  @include element('save-section') {
    margin-bottom: rem(40px);

    .ps-information-box {
      font-size: rem(20px);
      line-height: rem(20px);

      .ps-information-box__prepend {
        font-size: rem(32px);
      }
      .ps-information-box__title {
        font-weight: 900;
      }
      .ps-information-box__detail {
        font-weight: normal;
        color: $--color-greyscale-500;
      }
    }
  }
  @include element('restart-button') {
    padding: 0;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(40px);
    }
  }
}

@include block('add-money-button') {
  background-color: rgb(185, 157, 255);
  box-shadow: none;
  border: none;
  border-radius: $--border-radius-4;
  padding: rem(8px) 0;
  font-weight: 500;
  user-select: none;
  touch-action: manipulation;

  @include media-breakpoint-up(md) {
    padding: rem(12px) 0;
  }
}

@include block('disable-request-qr') {
  position: absolute;
  padding-top: rem(62px);
  width: 100%;
  height: 100%;

  @include element('timer') {
    font-weight: 900;
  }

  .ps-information-box {
    .ps-information-box__title {
      font-weight: 900;
    }
  }
}

.ps-deposit-qr__notifier {
  @include media-breakpoint-up(md) {
    min-width: rem(328px);
  }
}
