@include block('transaction-history') {
  width: 100%;

  > .ant-collapse {
    @include element('icon') {
      font-size: rem(14px) !important;
      margin-right: 15px;
    }
  }

  @include element('panel-item') {
    margin-bottom: rem(24px);

    > div.ant-collapse-content {
      transform: translateZ(0);

      > div.ant-collapse-content-box {
        padding: 0 !important;
      }
    }

    > div.ant-collapse-header {
      display: flex;
      align-items: baseline;
      border-bottom: solid rem(1px) $--color-greyscale-200;
      font-size: rem(20px);
      line-height: rem(20px);
      padding: rem(0 0 4px 0) !important;
      user-select: none;
      touch-action: manipulation;
      z-index: 10;

      .ant-collapse-header-text {
        color: $--color-greyscale-400;
      }

      > .ant-collapse-arrow {
        right: 0;
        font-size: rem(16px);
      }
    }

    .ant-collapse-expand-icon {
      padding-inline-end: 0px !important;
    }
  }
}
