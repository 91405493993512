@import 'libs/uikit/src/styles/global';

.container {
  padding: 12px 12px 64px;

  .profile {
    .detail {
      display: grid;
      grid-template-areas:
        'avatar name extra'
        'avatar level extra';
      grid-template-columns: 55px 165px auto;
      grid-template-rows: 60px 0px;
      gap: 8px;
      row-gap: 10px;

      .avatar {
        width: 100%;

        & > .guild {
          position: relative;
          width: 28px;
          height: 28px;
          float: right;
          margin-top: -15px;
          margin-right: -10px;
          z-index: 2;
        }
      }

      .extra {
        margin-top: 5px;

        .profile-details {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--color-primary-1);
          padding: 0 14px;
          box-shadow: unset;
          border: 1px solid var(--color-primary-1);
        }
      }

      .vip {
        margin-top: 5px;
      }
    }

    .level-progress {
      margin: 10px auto 4px;
      display: flex;
      flex-direction: column;

      & > .text {
        display: flex;
        justify-content: space-between;
        font-size: rem(16px);
        margin-bottom: -10px;
      }
    }

    .verify {
      display: flex;
      column-gap: 10px;
      align-items: center;
      margin-top: 12px;

      .text {
        color: var(--color-greyscale-500);
      }

      & > .icon {
        margin-left: auto;
      }
    }
  }

  .link {
    width: calc(50% - 8px);
    overflow: hidden;
  }
  .menus {
    gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0px;
      height: 60px;
      margin: auto;
      padding: 10px 8px;
      font-weight: 500;
      color: var(--color-black);
      white-space: nowrap;
      border: 1px solid var(--color-white-200);
      border-radius: 4px;

      &.primary {
        color: var(--color-white);
        background-color: var(--color-primary-1);
        border: none;
        box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
        margin-bottom: 6px;
        border-radius: 8px;
      }

      &.secondary {
        background-color: var(--color-violet-100);
        border: none;
        box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
        margin-bottom: 6px;
        border-radius: 8px;
      }

      :global(.ant-badge) {
        margin: 0 8px;
        & > :global(.ant-badge-dot) {
          left: 24px;
          top: 3px;
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .signout {
    color: var(--color-black);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
    font-weight: 500;
  }
}
