@import 'libs/uikit/src/styles/global';

.dark-mode {
  @include styles('dark-theme');
}

.light-mode {
  @include styles('light-theme');
}

a,
button {
  outline: none;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  color: #2a2a2e;
  background-color: #fff;
  font-size: $--font-size-base;
  line-height: $--line-height-base;
  scroll-behavior: auto !important;
}

a,
span,
p,
li {
  font-size: $--font-size-base default;
  line-height: $--line-height-base default;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $--font-size-base;
  line-height: $--line-height-base;
  color: var(--color-greyscale-600);
  background-color: var(--color-white);
  overflow-x: hidden; // NOTE : for landing animation

  &.lock-screen {
    overflow: hidden;
  }
}

a {
  color: var(--color-blue-200);
  text-decoration: none;
  transition-property: color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
p {
  line-height: 1.9;
  margin-top: 1em;
  margin-bottom: 1em;
}

%tag-h {
  margin: 0;
  font-weight: bolder;
  line-height: 1.35;
  color: var(--color-black);
}
h1 {
  @extend %tag-h;
  font-size: $--font-size-h1-mobile;
  line-height: $--line-height-h1;
  @include media('>lg') {
    font-size: $--font-size-h1-desktop;
  }
}
h2 {
  @extend %tag-h;
  font-size: $--font-size-h2-mobile;
  line-height: $--line-height-h2;
  @include media('>lg') {
    font-size: $--font-size-h2-desktop;
  }
}
h3 {
  @extend %tag-h;
  font-size: $--font-size-h3-mobile;
  line-height: $--line-height-h3;
  @include media('>lg') {
    font-size: $--font-size-h3-desktop;
  }
}
h4 {
  @extend %tag-h;
  font-size: $--font-size-h4-mobile;
  line-height: $--line-height-h4;
  @include media('>lg') {
    font-size: $--font-size-h4-desktop;
  }
}
h5 {
  @extend %tag-h;
  font-size: $--font-size-h5-mobile;
  line-height: $--line-height-h5;
  @include media('>lg') {
    font-size: $--font-size-h5-desktop;
  }
}

ul {
  padding-inline-start: 30px;
}

::placeholder {
  color: var(--color-greyscale-300);
}
