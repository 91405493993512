@include block('footer') {
  background-color: $--color-violet-600;
  padding-top: rem(27px);
  padding-bottom: rem(20px);
  flex-shrink: 0;
  min-height: rem(360px);

  @include media-breakpoint-up('sm') {
    min-height: rem(375px);
  }

  @include media-breakpoint-up('lg') {
    min-height: rem(210px);
  }

  @include element('text') {
    font-size: $--font-size-b3;
    color: $--color-greyscale-100;
    user-select: none;

    @include modifier('link') {
      cursor: pointer;
      &:hover {
        color: $--color-primary-2;
      }
    }
  }

  @include element('line') {
    line-height: rem(20px);
  }

  @include element('icon') {
    font-size: rem(32px);
    color: $--color-greyscale-100;
    margin-right: rem(5px);
  }

  @include element('bank') {
    margin-top: rem(24px);

    @include media-breakpoint-up('lg') {
      margin-top: rem(0px);
    }
  }

  @include element('bank-list') {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: rem(192px);
    height: rem(32px);
    list-style: none;
    user-select: none;

    &:last-child {
      margin-top: rem(16px);
    }
  }

  @include element('bank-list-item') {
    width: rem(32px);
    height: rem(32px);
  }
  @include element('logo') {
    width: rem(124px);
    height: auto;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
    margin-top: rem(-15px);
    @include media-breakpoint-up('sm') {
      // Original before change = width: rem(160px);
      width: rem(124px);
    }

    &--smaller {
      width: rem(124px);
    }

    @include media-breakpoint-up('lg') {
      width: rem(150px);
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
