@import 'libs/uikit/src/styles/global';

.container {
  &:global(.ant-btn) {
    overflow: hidden;
    box-shadow: $button--box-shadow;
    font-weight: 700;
    padding: 0 23px;

    &:active {
      transform: scale(0.95);
    }
  }

  &:global(.ant-btn) {
    &:hover {
      border-color: transparent;
    }
  }

  &:global(.ant-btn-default) {
    &:hover {
      color: var(--color-black);
    }
  }

  &:global(.ant-btn-primary) {
    box-shadow: $button--box-shadow;

    &:not(:disabled) {
      &:hover {
        background-color: $button--background-hover;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
}
