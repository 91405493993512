@include block('transaction-collapse') {
  width: 100%;

  > .ant-collapse-item {
    margin-top: rem(8px);
    border: none;
  }

  @include element('panel-item') {
    background-color: $--color-white;

    .ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-arrow {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .ant-collapse-item .anticon svg {
    font-size: rem(14px);
  }
  .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    right: 15px;
    bottom: 14px;
    color: $--color-greyscale-400;
    font-size: 15px !important;
    transform: rotate(90deg);
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      .ant-collapse-arrow {
        transform: rotate(180deg);
      }
    }
  }

  @include modifier('commission-description') {
    font-size: 20px;
    margin: 0px;
    margin-left: 13px;
    line-height: 1;
  }

  @include modifier('received') {
    color: $--color-success;
    opacity: 0.9;
  }
}

@include block('bonus-transaction-header') {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @include element('wrapper') {
    margin-left: 12px;
  }

  @include element('icon') {
    display: inline-block;
    margin-right: 5px;
    margin-top: -2px;
    margin-left: -12px;

    @include modifier('active') {
      color: $--color-blue-200;
    }
  }

  @include element('id') {
    color: $--color-white-200;
    background-color: #eaeaea;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -16px;
    margin-right: 10px;
    padding: 0px 23px;
    font-size: rem(24px);
    line-height: rem(24px);
  }

  @include element('title') {
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  @include element('bonus-flag') {
    display: inline-block;
    margin-left: 4px;
    padding: 2px 5px;
    color: $--color-greyscale-600;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    background-color: #f6c244;
    border-radius: 4px;
  }
  @include element('amount') {
    display: flex;
    align-items: baseline;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
    gap: 5px;
  }
  @include element('coin') {
    height: rem(16px);
    width: fit-content;
  }
  @include element('date-time') {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $--color-greyscale-400;
  }
}

@include block('cash-transaction-header') {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @include element('swap-icon') {
    color: $--color-primary-1;
    background-color: #f0eaff;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -16px;
    margin-right: 10px;
    padding: 0px 23px;
    font-size: rem(24px);
    line-height: rem(24px);
  }

  @include element('icon') {
    display: inline-block;
    margin-right: 5px;
    margin-top: -2px;

    @include modifier('COMPLETED') {
      color: $--color-green-200;
    }
    @include modifier('TRANSFER_BACK') {
      color: $--color-green-200;
    }
    @include modifier('PENDING') {
      color: $--color-blue-200;
    }
    @include modifier('WAITING_TRANSFER') {
      color: $--color-blue-200;
    }
    @include modifier('REJECTED') {
      color: $--color-red-200;
    }
    @include modifier('HOLD_ON') {
      color: $--color-greyscale-300;
    }

    @include modifier('ACTIVE') {
      color: $--color-green-200;
    }
    @include modifier('CONVERTED') {
      color: $--color-green-200;
    }
    @include modifier('USED') {
      color: $--color-greyscale-300;
    }
    @include modifier('EXPIRED') {
      color: $--color-greyscale-300;
    }
    @include modifier('CANCELED') {
      color: $--color-red-200;
    }
    @include modifier('REVOKED') {
      color: $--color-red-200;
    }
  }
  @include element('title') {
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  @include element('bonus-flag') {
    display: inline-block;
    margin-left: 4px;
    padding: 2px 5px;
    color: $--color-greyscale-600;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    background-color: #f6c244;
    border-radius: 4px;
  }
  @include element('amount') {
    display: flex;
    align-items: baseline;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
    gap: 5px;
  }
  @include element('coin') {
    height: rem(16px);
    width: fit-content;
  }
  @include element('date-time') {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $--color-greyscale-400;
  }
}
@include block('bonus-history-content') {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: $--color-greyscale-400;
  border-top: 1px solid $--color-greyscale-200;
  margin-top: -10px;
  padding-top: 6px;

  @include element('status') {
    display: flex;
    justify-content: space-between;
  }
  @include element('detail') {
    display: flex;
    justify-content: space-between;
    font-weight: 400 !important;
  }
  @include element('progressbar') {
    color: #f6c244;
  }
}

@include block('bonus-information-header') {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: rem(8px) rem(16px);
  font-size: rem(20px);
  line-height: rem(20px);

  strong {
    font-weight: 500;
  }

  @include element('inline') {
    display: flex;
    justify-content: space-between;
    margin-top: rem(4px);
  }
}
@include block('bonus-information-content') {
  display: flex;
  justify-content: space-between;
  font-size: rem(20px);
  line-height: rem(20px);

  strong {
    font-weight: 900;
  }

  @include element('left') {
    display: flex;
    flex-direction: column;
    @include modifier('turnover-detail') {
      color: $--color-greyscale-400;
      margin-top: rem(4px);
      margin-bottom: rem(16px);
    }
    @include modifier('bonus-layout') {
      display: flex;
      white-space: nowrap;
    }
    @include modifier('bonus-title') {
      color: $--color-greyscale-400;
      :not(:first-child) {
        margin-top: rem(4px);
      }
    }
  }

  @include element('remain') {
    font-weight: 900;
    color: $--color-greyscale-500;
  }

  @include element('total') {
    color: $--color-greyscale-400;
  }

  @include element('right') {
    color: $--color-greyscale-400;
    width: rem(100px);
    height: rem(100px);

    @include modifier('title') {
      position: absolute;
      top: 29%;
    }
    @include modifier('percent') {
      position: absolute;
      top: 44%;
      line-height: rem(32px);
    }
  }
}
