@include block('leaderboard-stage') {
  position: relative;

  @include element('wrapper') {
    display: flex;
    justify-content: space-between;
    padding-top: rem(16px);
    padding-bottom: rem(12px);

    @include media-breakpoint-up(md) {
      justify-content: center;
      padding-top: rem(32px);
      padding-bottom: rem(24px);
    }
  }

  @include element('first') {
    @include media-breakpoint-up(md) {
      margin-right: rem(56px);
      margin-left: rem(56px);
    }
  }

  @include element('second') {
    margin-top: rem(52px);

    @include media-breakpoint-up(md) {
      margin-top: rem(64px);
    }
  }

  @include element('third') {
    margin-top: rem(52px);

    @include media-breakpoint-up(md) {
      margin-top: rem(64px);
    }
  }
}
