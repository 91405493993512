@import 'libs/uikit/src/styles/global';

.container {
  margin-right: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  .icon {
    width: 20px;
    height: 20px;
  }
}
