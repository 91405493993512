@include block('withdrawal-content') {
  @include element('message') {
    margin-top: rem(16px);
    margin-bottom: rem(16px);
    font-size: 20px;
    line-height: 1;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: rem(32px);
      margin-bottom: rem(32px);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(16px);
    }
  }

  @include element('btn') {
    margin-right: auto;
    margin-left: auto;
    color: $--color-blue-200;
    text-decoration: underline;
  }
}
