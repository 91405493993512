@include block('skeleton') {
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(221, 221, 221, 1) 50%,
    rgba(240, 240, 240, 1) 100%
  );
  background-size: 300%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  animation: skeleting 12s linear infinite;
}
@include block('skeleton-text') {
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(221, 221, 221, 1) 50%,
    rgba(240, 240, 240, 1) 100%
  );
  background-size: 300%;
  display: block;
  width: 6rem;
  height: 1ch;
  margin: 12px 0px;
  animation: skeleting 12s linear infinite;
}
@keyframes skeleting {
  0% {
    background-position-x: 400%;
  }
  100% {
    background-position-x: -400%;
  }
}
