@import 'libs/uikit/src/styles/global';

.input {
  &:hover,
  &:focus,
  &:active {
    border-color: $input--border-hover-color;
    box-shadow: none;
  }

  &:global(.ant-input-affix-wrapper) {
    border: 2px solid var(--color-greyscale-400);
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      border-color: $input--border-hover-color;
      border-inline-end-width: 2px;
      box-shadow: none;
    }
  }
}
