@include block('leaderboard-info') {
  min-height: rem(56px);

  @include media-breakpoint-up(md) {
    min-height: rem(64px);
  }

  @include element('wrapper') {
    padding-bottom: rem(12px);

    @include media-breakpoint-up(md) {
      padding-bottom: rem(16px);
    }
  }

  @include element('title') {
    margin-bottom: rem(4px);
    color: $--color-violet-600;
    font-size: rem(24px);
    font-weight: 900;
    line-height: 1;
    text-align: center;
  }

  @include element('message') {
    color: $--color-violet-600;
    font-size: rem(16px);
    font-weight: 400;
    line-height: 1;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: rem(20px);
    }
  }

  @include modifier('counting-down') {
    @include element('title') {
      font-size: rem(16px);

      @include media-breakpoint-up(md) {
        font-size: rem(20px);
      }
    }

    @include element('message') {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(24px);

      > .ps-icon-clock {
        margin-right: rem(8px);
      }
    }

    @include element('time') {
      margin-right: rem(4px);
      font-weight: 900;
    }

    @include element('unit') {
      margin-right: rem(4px);
      font-size: rem(16px);
      font-weight: 400;
      line-height: 1.5;

      @include media-breakpoint-up(md) {
        font-size: rem(18px);
        line-height: 1.33;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
