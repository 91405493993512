$themes: (
  color-violet-100: (
    light-theme: #efe6fd,
    dark-theme: #abcdef,
  ),
  color-violet-200: (
    light-theme: #d0b3fa,
    dark-theme: #abcdef,
  ),
  color-violet-300: (
    light-theme: #914df3,
    dark-theme: #abcdef,
  ),
  color-violet-400: (
    light-theme: #6200ee,
    dark-theme: #abcdef,
  ),
  color-violet-500: (
    light-theme: #4e00be,
    dark-theme: #abcdef,
  ),
  color-violet-600: (
    light-theme: #310077,
    dark-theme: #abcdef,
  ),
  color-orange-100: (
    light-theme: #fff4e6,
    dark-theme: #abcdef,
  ),
  color-orange-200: (
    light-theme: #ffdeb3,
    dark-theme: #abcdef,
  ),
  color-orange-300: (
    light-theme: #ffb24d,
    dark-theme: #abcdef,
  ),
  color-orange-400: (
    light-theme: #ff9100,
    dark-theme: #abcdef,
  ),
  color-orange-500: (
    light-theme: #cc7400,
    dark-theme: #abcdef,
  ),
  color-orange-600: (
    light-theme: #804900,
    dark-theme: #abcdef,
  ),
  color-greyscale-100: (
    light-theme: #fcfcfc,
    dark-theme: #abcdef,
  ),
  color-greyscale-200: (
    light-theme: #d4d4d5,
    dark-theme: #abcdef,
  ),
  color-greyscale-300: (
    light-theme: #aaaaab,
    dark-theme: #abcdef,
  ),
  color-greyscale-400: (
    light-theme: #7f7f82,
    dark-theme: #abcdef,
  ),
  color-greyscale-500: (
    light-theme: #555558,
    dark-theme: #abcdef,
  ),
  color-greyscale-600: (
    light-theme: #2a2a2e,
    dark-theme: #abcdef,
  ),
  color-yellow-100: (
    light-theme: #fef6e3,
    dark-theme: #abcdef,
  ),
  color-yellow-200: (
    light-theme: #f6c244,
    dark-theme: #abcdef,
  ),
  color-lightblue-100: (
    light-theme: #e6f7fc,
    dark-theme: #abcdef,
  ),
  color-lightblue-200: (
    light-theme: #5bc8ec,
    dark-theme: #abcdef,
  ),
  color-blue-100: (
    light-theme: #daeafd,
    dark-theme: #abcdef,
  ),
  color-blue-200: (
    light-theme: #0573f3,
    dark-theme: #abcdef,
  ),
  color-white: (
    light-theme: #ffffff,
    dark-theme: #abcdef,
  ),
  color-white-200: (
    light-theme: #b5b5b5b5,
    dark-theme: #b5b5b5b5,
  ),
  color-black: (
    light-theme: #2a2a2e,
    dark-theme: #abcdef,
  ),
  color-red: (
    light-theme: #c8373e,
    dark-theme: #abcdef,
  ),
  color-transparent: (
    light-theme: transparent,
    dark-theme: #abcdef,
  ),
  color-red-100: (
    light-theme: #f7e1e2,
    dark-theme: #abcdef,
  ),
  color-red-200: (
    light-theme: #c8373e,
    dark-theme: #abcdef,
  ),
  color-green-100: (
    light-theme: #e2ede6,
    dark-theme: #abcdef,
  ),
  color-green-200: (
    light-theme: #408558,
    dark-theme: #abcdef,
  ),
  // mainly color
  color-primary-1:
    (
      light-theme: var(--color-violet-400),
      dark-theme: var(--color-violet-400),
    ),
  color-primary-2: (
    light-theme: var(--color-orange-400),
    dark-theme: var(--color-orange-400),
  ),
  color-success: (
    light-theme: var(--color-green-200),
    dark-theme: var(--color-green-200),
  ),
  color-warning: (
    light-theme: var(--color-yellow-200),
    dark-theme: var(--color-yellow-200),
  ),
  color-danger: (
    light-theme: var(--color-red-200),
    dark-theme: var(--color-red-200),
  ),
  // box shadow
  box-shadow-1:
    (
      light-theme: 0 4px 12px 0 rgba(1, 27, 42, 0.14),
      dark-theme: 0 4px 12px 0 rgba(1, 27, 42, 0.14),
    ),
  box-shadow-2: (
    light-theme: 0 2px 10px 0 rgba(1, 27, 42, 0.12),
    dark-theme: 0 2px 10px 0 rgba(1, 27, 42, 0.12),
  ),
  box-shadow-3: (
    light-theme: 0 2px 8px rgba(1, 27, 42, 0.1),
    dark-theme: 0 2px 8px rgba(1, 27, 42, 0.1),
  ),
  box-shadow-4: (
    light-theme: 0 1px 6px rgba(1, 27, 42, 0.1),
    dark-theme: 0 1px 6px rgba(1, 27, 42, 0.1),
  ),
);

@mixin styles($mode) {
  @each $key, $map in $themes {
    @each $prop, $color in $map {
      @if $prop == $mode {
        --#{$key}: #{$color};
      }
    }
  }
}
