@include block('wallet-section') {
  margin-top: rem(16px);
  margin-bottom: rem(24px);
  @include element('wrapper') {
    margin-bottom: rem(16px);
  }
  @include element('slider') {
    @include modifier('no-bonus') {
      .slick-list {
        > .slick-track {
          justify-content: center;
        }
      }
    }

    .slick-list {
      overflow-x: hidden;

      > .slick-track {
        display: flex;
      }
    }

    .slick-arrow,
    .slick-next {
      display: none !important;
    }

    @include media-breakpoint-up('md') {
      .slick-arrow,
      .slick-next {
        display: block !important;
      }
    }
  }

  @include element('wallet-desktop') {
    position: absolute;
    display: flex;
    width: 833px;
    height: 100%;
    justify-content: space-between;
    z-index: 0;
  }
  @include element('arrow') {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  @include element('dots-container') {
    display: flex;
    justify-content: center;
    margin-top: rem(16px);
  }
  @include element('dots-layout') {
    display: flex;
    justify-content: space-between;
    width: rem(50px);
  }
  @include element('active') {
    background-color: $--color-primary-1;
  }
}

@include block('wallet-section-track-dots-layout') {
  bottom: '35%';
}
.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

@include block('wallet-section-track-dots') {
  // position: absolute;
  // height: rem(32px);
  margin: 0;
  padding: 0;
  // bottom: rem(8px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up('md') {
    bottom: rem(4px);
  }
  li {
    height: rem(32px);
    width: rem(32px);
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.slick-active {
      :first-child {
        background-color: $--color-primary-1;
      }
    }
  }
}

@include block('wallet-section-dot') {
  width: rem(8px);
  height: rem(8px);
  margin: 0;
  padding: 0;
  color: $--color-primary-1;
  border: 1px solid $--color-primary-1;
  border-radius: $--border-radius-h;
  @include media-breakpoint-up('md') {
    width: rem(15px);
    height: rem(15px);
  }
}
