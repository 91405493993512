@include block('leaderboard-history-section') {
  @include element('header') {
    text-align: center;
    font-weight: 900;
    font-size: rem(42px);
  }
  @include element('sub-week') {
    color: $--color-primary-1;
    text-align: center;
    font-weight: 500;
    font-size: rem(24px);
    line-height: rem(20px);
  }
  @include element('sub-day') {
    text-align: center;
    color: $--color-greyscale-400;
    font-size: rem(20px);
  }
}

@include block('leaderboard-history-empty-section') {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(64px) 0;
  @include element('image') {
    width: rem(133px);
    height: rem(89px);
    margin-bottom: rem(24px);
    @include media-breakpoint-up('md') {
      width: rem(177px);
      height: rem(115px);
    }
  }
  @include element('text') {
    font-size: rem(20px);
    line-height: rem(20px);
    text-align: center;
    @include media-breakpoint-up('md') {
      font-size: rem(24px);
      line-height: rem(24px);
    }
    @include modifier('title') {
      font-weight: 900;
    }
  }
}
