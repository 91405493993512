@import 'libs/uikit/src/styles/global';

.extra {
  margin-bottom: 45px;
}

.wrapper {
  background-color: var(--color-violet-600);
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--color-white);
    font-size: rem(20px);
    padding: 16px 16px;
    margin: auto;
    max-width: 524px;

    @include media('>md') {
      max-width: 752px;
    }

    @include media('>lg') {
      max-width: 992px;
    }

    @include media('>xl') {
      max-width: 1076px;
    }

    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .menus-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include media('>md') {
        grid-template-columns: repeat(3, 1fr);
      }

      .menus {
        list-style: none;
        padding-left: unset;
        margin-bottom: 0;

        li,
        a {
          font-size: 20px;
          line-height: 1.5;
        }
      }

      .ssl {
        display: flex;
        gap: 8px;

        .img {
          width: 30px;
          height: fit-content;
          margin-top: 5px;
        }

        & > .text {
          display: flex;
          flex-direction: column;
          line-height: 20px;

          span {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }

    .banks {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;

      img {
        width: 32px;
        height: fit-content;
      }
    }

    .certifications {
      margin: auto;

      img {
        max-width: 100%;
        height: fit-content;
      }
    }

    .divider {
      border-block-start: 1px solid rgba(255, 255, 255, 0.37);
    }

    .description {
      text-align: center;
      margin-bottom: 36px;

      .text {
        margin-bottom: 38px;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: rem(20px);
      }

      .copyright {
        margin: auto;
        width: 200px;
      }
    }

    a {
      color: var(--color-white);
    }

    .logo {
      width: 130px;
      height: auto;
    }
  }
}

.legal-modal {
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
  }

  .content {
    height: calc(100vh - 250px);
    overflow-y: auto;
    font-size: rem(20px);

    p {
      margin: 0px;
      line-height: 1;
    }
  }
}
