@import 'libs/uikit/src/styles/global';

.container {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  background-color: white;
  box-shadow: rgb(42 42 46) 0px 4px 12px 0px;
  z-index: 20;
  height: 54px;

  .item {
    display: flex;
    align-items: center;
    width: 100%;

    .active {
      color: rgb(98, 0, 238) !important;
    }

    .button {
      position: relative;
      border: none;
      box-shadow: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: rgb(85, 85, 88);
      transform: scale(1);

      & > :global(.anticon) {
        & > svg {
          width: rem(18px);
          height: rem(18px);
        }
      }

      & > svg {
        width: rem(40px);
        height: rem(40px);
        margin-top: -24px;
      }

      .badge {
        position: absolute;
        background-color: red;
        border-radius: 50%;
        right: 32%;
        top: 5%;
        border: 2px solid white;
        width: 11px;
        height: 11px;
      }

      span {
        margin-inline-start: 0 !important;
      }
    }
  }

  .label {
    margin-top: 5px;
    line-height: 1;
  }
}
