@include block('calendar') {
  width: 100%;
  border: 1px solid $--color-white-200;

  @include modifier('error') {
    border-color: $--color-red-200 !important;
    caret-color: $--color-red-200;
  }
}

@include block('calendar-container') {
  width: 100%;
  @include element('title') {
    font-size: rem(20px);
    line-height: rem(20px);
    color: $--color-greyscale-600;
    line-height: rem(20px);
  }
}

@include block('calendar-error-layout') {
  display: flex;
  margin: rem(4px 0);
  height: rem(20px);
  font-family: inherit;
}

@include block('calendar-message') {
  color: $--color-red-200;
  word-break: break-word;
  line-height: rem(20px);
  font-size: rem(20px);
}

@include block('calendar-icon-caution-outline') {
  font-size: rem(15px);
  @include modifier('error') {
    color: $--color-red-200;
    margin-right: rem(4px);
  }
}

@include block('calendar-icon-caution-filled') {
  font-size: rem(16px);
  position: absolute;
  margin-left: rem(16px);
  margin-top: rem(14px);
  @include modifier('error') {
    color: $--color-red-200;
  }
}

@include block('calendar-panel') {
  .ant-picker-panel-layout {
    justify-content: center;
  }
}
