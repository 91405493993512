@include block('promotions-section') {
  margin-top: 8px;
  margin-bottom: 44px;

  @include element('header') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(44px);
    margin-bottom: rem(8px);

    @include media-breakpoint-up(md) {
      height: rem(50px);
    }
  }
  @include element('title') {
    margin-bottom: 0;
    color: $--color-greyscale-600;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: $--font-size-h4-desktop;
    }
  }
  @include element('image-container') {
    border-radius: 8px;
    cursor: pointer;
  }
  @include element('divider') {
    margin-top: rem(56px);
    margin-bottom: rem(24px);
    background-color: $--color-greyscale-200;
    opacity: 1;
    @include media-breakpoint-up(lg) {
      margin-top: rem(64px);
    }
  }
  @include element('divider-game-register') {
    margin-bottom: rem(0px);
  }
  .ant-carousel .slick-next::before,
  .ant-carousel .slick-prev::before {
    display: none;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    width: rem(24px);
    height: rem(24px);
    font-size: inherit;
    color: $--color-greyscale-100;
    top: 55%;
    z-index: 9;

    > i.ps-promotions-section__icon {
      width: rem(24px);
      height: rem(24px);
    }
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-next:focus {
    right: 4.5%;
    @include media-breakpoint-up(lg) {
      right: 2%;
    }
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-prev:focus {
    left: 4.5%;
    @include media-breakpoint-up(lg) {
      left: 2%;
    }
  }
  .ant-carousel .slick-dots li button:before {
    display: none;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: rem(-40px);
    @include media-breakpoint-up(lg) {
      bottom: rem(-46px);
    }
  }
  .ant-carousel .slick-dots {
    margin: 0%;
    li {
      height: rem(30px);
      width: rem(30px);
      margin: 0;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    li > button {
      width: rem(9px);
      height: rem(9px);
      margin: 0;
      padding: 0;
      border: 1px solid $--color-violet-400;
      border-radius: 50%;
    }
    li.slick-active button {
      background-color: $--color-violet-400;
    }
  }
}
