@import 'libs/uikit/src/styles/global';

.container {
  position: relative;

  .popup-container {
    position: absolute;
    top: -52px;
    left: 0px;
    right: 0px;
    transform: translateY(-100%);
    width: 40px;
    text-align: center;

    .label {
      font-size: rem(16px);
      color: var(--color-primary-1);
    }
  }

  .main-icon {
    margin-top: -21px;
  }

  .close-icon {
    width: 39px;
    height: 39px;
    margin-top: -18px;
    font-weight: 900;
    font-size: 20px;
    stroke-width: 20;
    stroke: white;
    color: white;
    background-color: var(--color-greyscale-300);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: var(--color-primary-1);
    }
  }

  .background-cover {
    position: absolute;
    bottom: 25px;
    z-index: -1;
    transform: translateX(50%);
    background: var(--color-white);
    right: 0;
    width: 120vw;
    height: 100vh;
  }
}
