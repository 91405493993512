@include block('game-company') {
  margin-bottom: rem(16px);
  padding: 0px 17px;

  @include element('container') {
    margin-bottom: 8px;

    @include element('button-group') {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }

    @include element('banner-btn') {
      background-color: $--color-violet-600;
      color: $--color-white;
      min-width: 130px;
      height: 40px;
      margin-top: -60px;
    }
  }

  @include element('btn-container') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    column-gap: 4px;
    margin: 0 -3px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 14px;
    }
  }

  @include element('btn') {
    &:hover {
      cursor: pointer;
    }

    @include modifier('disable') {
      &:hover {
        cursor: default;
      }
    }
  }
}
