@import 'libs/uikit/src/styles/global';

.container {
  display: inline-block;
  position: relative;
  padding: 0px;
  width: auto;
  font-size: 0px;
  line-height: 0px;

  .avatar {
    border-radius: 50%;
  }

  .vip {
    width: calc(100% + 10px);
    margin-left: -5px;
  }

  .guild {
    position: absolute;
    top: 100%;
    right: -1px;
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: right;
    transform: translateY(-50%);
    z-index: 12;
    border-radius: 50%;
  }

  .close-short-profile {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
    background-color: #ffffffb3;
    backdrop-filter: blur(2px);
    z-index: 12;
    border-radius: 100%;
    overflow: hidden;
    font-size: rem(20px);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
