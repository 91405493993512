@import 'libs/uikit/src/styles/global';

.affiliate-container {
  background: var(--color-orange-100) !important;
}

.app-switcher-container {
  color: var(--color-primary-1);
  cursor: pointer;

  .down-icon {
    font-size: rem(16px);
    margin-left: 3px;
  }
}

.modal-cookie-sync-tabs {
  :global(.ant-modal-title) {
    font-size: rem(24px) !important;
    padding-left: 16px;
    padding-top: 16px;
  }

  :global(.ant-modal-body) {
    padding: 0px !important;
    padding-left: 16px !important;
    font-size: rem(20px) !important;
  }

  :global(.ant-btn-default) {
    display: none;
  }

  :global(.ant-btn-primary) {
    font-size: rem(14px);
    padding-bottom: 16px;
    padding-right: 16px;
    margin-right: 5px;

    :global(.ant-wave) {
      display: none;
    }
  }
}

.dropdown-menu {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: rem(24px);
  font-weight: 500;
}

.overlay-dropdown {
  position: fixed;
  top: 50px !important;

  :global(.ant-dropdown-menu) {
    box-shadow: none;
  }
}
