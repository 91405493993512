@include block('section-title') {
  display: flex;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: $--color-greyscale-600;
  @include modifier('space-between') {
    justify-content: space-between;
  }
  @include modifier('wrap') {
    flex-wrap: wrap;
    flex-direction: column;
  }
  > h4 {
    display: block;
    font-weight: 800;
    font-size: 34px;
    line-height: 34px;
    align-items: center;
    color: $--color-greyscale-600;
    @include media-breakpoint-up(md) {
      font-size: 46px;
    }
  }
  > h5 {
    display: block;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: $--color-greyscale-500;
  }
  @include element('link') {
    display: inline-flex;
    align-items: center;
    @include element('link-text') {
      line-height: 1;
      margin-right: 2px;
      text-decoration: none;
      text-underline-offset: 1px;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
    }
    svg {
      font-size: 13px;
    }
  }
}
@include block('spacer') {
  display: block;
  height: 12px;
  @include media-breakpoint-up(md) {
    height: 24px;
  }
}
