@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-content: center;
}
@include block('locked-scroll') {
  overflow: hidden;
}
@include block('dailyquest') {
  padding-bottom: 3rem;
}
@include block('loader-filter') {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: $--color-black;
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
  user-select: none;
  z-index: 200;

  @include modifier('active') {
    opacity: 0.85;
    transition: 0.2s 0.2s;
  }

  @include element('loading-animated') {
    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;

    > * {
      filter: grayscale(1) brightness(4);
    }
  }
}
@include block('section-header') {
  font-style: normal;
  font-weight: 900;
  font-size: rem(34.56px);
  line-height: rem(35px);
  color: $--color-greyscale-600;

  @include element('subtitle') {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: $--color-violet-600;
  }
  @include element('refresh') {
    text-align: right;
    transform: translateX(16px);

    transform-origin: 100% 100%;
  }
}
@include block('section-timer') {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 8px 12px 0;
  font-style: normal;
  font-weight: 900;
  font-size: rem(21px);
  line-height: rem(21px);
  color: $--color-greyscale-500;
  @include modifier('warning') {
    color: $--color-primary-1;
  }
  @include element('timer-icon') {
    margin-right: 1px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }
  @include element('text') {
    padding: rem(4px) 0;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 16px;
    > span {
      margin: 0 0.4ch;
    }
  }
}

@include block('main-quest-section') {
  position: relative;
  padding: 0;
  margin: 0;
  user-select: none;

  @include media-breakpoint-up(md) {
    margin: 0.2rem auto;
  }

  .ps-icon-ticket {
    font-size: rem(23px);
    margin-bottom: rem(1px);
  }

  @include element('card') {
    position: relative;
    height: rem(168px);
    padding: rem(20px 16px);
    background-color: $--color-violet-600;
    border-radius: $--border-radius-1;
    box-shadow: $--box-shadow-1;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      height: rem(220px);
    }
    @include media-breakpoint-up(lg) {
      height: 220px;
    }
    @include modifier('skeleton') {
      // position: relative;
      // display: flex;
      // justify-content: center;
      // height: calc(100% - 0.2rem);
      background: rgba(190, 190, 190, 0.2);
    }
  }

  @include element('main-image-dailyquests-main-quest') {
    position: absolute;
    width: 36%;
    top: -1%;
    left: 3%;

    @include media-breakpoint-up(md) {
      position: absolute;
      object-fit: contain;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 6%;
    }
  }

  @include element('content-wrapper') {
    position: relative;
    display: flex;
    justify-content: center;
    height: calc(100% - 0.2rem);
  }

  @include element('bubble') {
    text-align: center;
    height: rem(62px);

    > span {
      z-index: 1;
    }
    @include media-breakpoint-up(md) {
      position: relative;
      top: 0;
      right: 0;
      margin-bottom: rem(7px);
      height: auto;
    }
  }

  @include element('bubble-action') {
    cursor: pointer;

    span {
      border-bottom: rem(1px) solid $--color-greyscale-400;
      display: inline-block;
    }
  }
  @include element('info') {
    color: white;
    text-align: center;
    font-size: rem(20px);
    font-weight: 300;
    line-height: rem(28px);
    z-index: 1;
    padding-left: 33%;

    @include media-breakpoint-up(md) {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include element('title') {
    font-size: 36px;
    font-weight: 700;
    line-height: rem(28px);

    @include media-breakpoint-up(md) {
      font-size: 62px;
      line-height: 1ch;
      margin-top: 7px;
      white-space: nowrap;
    }
  }

  @include element('quest-progress-wrapper') {
    @include media-breakpoint-up(lg) {
      margin: 12px auto;
    }
  }

  @include element('progress-wrapper') {
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: rem(245px);
    }
  }

  @include element('get-reward-btn') {
    margin: 1em auto 0;
    font-weight: 500;
    box-shadow: none;
    width: fit-content;
    line-height: 1ch;
    height: rem(40px);

    &:disabled {
      color: $--color-greyscale-300;
      background-color: $--color-greyscale-200;
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      font-size: rem(24px);
      font-weight: 500;
      height: rem(48px);
      width: rem(210px);
    }
  }
}

@include block('gamequests') {
  @include element('header-section') {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: rem(20px) 0;

    @include modifier('normal') {
      align-items: normal;
    }
    @include modifier('center') {
      align-items: center;
    }
  }
  @include element('sub-title') {
    font-size: rem(24px);
    font-weight: 300;
    color: $--color-greyscale-600;
  }
  @include element('list') {
    margin-top: rem(16px);
  }
  @include element('title-label') {
    font-size: rem(16px);
    color: $--color-greyscale-600;
    text-align: right;
  }
  @include element('title-link') {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 0.83333333rem;
  }
  @include element('refresh-timestamp') {
    font-size: rem(16px);
    color: $--color-greyscale-600;
    margin-top: rem(4px);
    margin-right: rem(10px);
    font-weight: 400;
    line-height: 1em;
  }
  @include element('reset-title') {
    font-size: rem(28px);
    line-height: rem(16px);
  }
  @include element('empty-list') {
    width: 100%;
    margin: 2rem auto;
    text-align: center;

    font-style: normal;
    font-weight: normal;
    font-size: rem(24px);
    line-height: rem(24px);
    text-align: center;
    color: $--color-black;
    img {
      display: inline-block;
      width: 100%;
      max-width: 7rem;
    }
  }
}
@include block('quest-modal') {
  font-size: rem(24px);
  @include element('content') {
    @include flexCenter;
    font-weight: 800;

    > i {
      margin-right: rem(3px);
    }
  }
}
@include block('modal-congrat') {
  position: relative;
  padding-top: 60px;
  @include element('img') {
    position: absolute;
    background-image: url('/images/dailyquests/PIGSPIN_Congrats.png');
    width: 260px;
    height: 260px;
    left: 60%;
    top: -25px;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .claim-success-modal {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -129px;
  }
}
@include block('daily-quest-refresh-button') {
  display: inline-block;
  height: 20px;
  padding: 0 6px;
  margin-right: rem(6px);
  vertical-align: middle;
  box-sizing: border-box;

  background: transparent;
  border: 2px solid transparent;
  border-radius: rem(32px);
  box-shadow: none !important;

  color: $--color-violet-400;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 0;
  filter: grayscale(1);
  opacity: 0.6;
  transition: 0.2s;
  @include modifier('refreshing') {
    pointer-events: none;
    filter: grayscale(0);
    opacity: 1;

    .ps-icon-refresh {
      animation: refresherRotation 0.3s 1 forwards ease-out;
      @keyframes refresherRotation {
        0% {
          transform: scale(0.9);
        }
        10% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  * {
    padding: 0 !important;
  }
  .ps-icon-refresh {
    font-size: rem(16px);
    padding-right: rem(8px);
  }
  @include modifier('mini') {
    transform: scale(0.8);
    transform-origin: left;
  }
}

@include block('pig-shop') {
  @include element('back') {
    font-size: rem(24px);
    font-weight: 400;
    span {
      font-size: 0.6em;
    }
  }
  @include element('text') {
    margin-top: rem(20px);
    font-size: rem(24px);
    font-weight: 400;
    line-height: 3ch;
    * {
      display: inline-block;
      vertical-align: top;
    }
    h5 {
      display: block;
      font-weight: 500;
      line-height: inherit;
      margin: inherit;
      padding: inherit;
    }
    span {
      font-size: rem(24px);
    }
  }
}
@include block('tooltip-icon') {
  font-size: 1ch;
  width: rem(40px);
  transform: translateX(-5px);
  color: $--color-blue-200;
  cursor: pointer;
}

@include block('tutorial-game-section') {
  position: relative;
  .slick-dots {
    bottom: rem(32px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
@include block('tutorial-carousel') {
  width: 100%;
  position: relative;
  @include element('top-layer') {
    border-radius: rem(8px);
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    width: 100%;
    z-index: 1;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
  @include modifier('layout') {
    width: inherit;
    min-height: calc(75vh - 16px);
    position: relative;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(sm) {
      min-height: calc(80vh - 16px);
    }
    @include media-breakpoint-up(xl) {
      min-height: 474.67px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 593.33px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 667.5px;
    }
    @media only screen and (min-width: 1920px) {
      min-height: 890px;
    }
  }

  @include element('image') {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover !important;
    @include media-breakpoint-up(lg) {
      object-fit: fill !important;
    }
  }

  @include element('item') {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    bottom: rem(64px);
    @include media-breakpoint-up(lg) {
      bottom: rem(88px);
    }
    h2,
    h1 {
      text-align: center;
      color: $--color-greyscale-100;
      overflow: hidden;
      word-wrap: break-word;
      line-height: rem(50px);
      max-height: rem(100px);
      @include media-breakpoint-up(lg) {
        line-height: rem(136px);
        max-height: rem(272px);
      }
      margin: 0;
    }

    @include modifier('text') {
      min-width: 160px;
      height: rem(52px);
      margin: auto;
      line-height: rem(20px);
      color: $--color-greyscale-100;
    }
  }
}
@include block('tutorial-carousel') {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgb(29 29 29 / 90%);
  z-index: 300;
  opacity: 0;
  transform: translateX(200%);
  @include modifier('active') {
    opacity: 1;
    transform: translateX(0%);
  }
  @include element('close') {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    color: white;
  }
  @include block('tutorial-carousel-track-dots-layout') {
    bottom: '35%';
  }

  @include block('tutorial-carousel-slide') {
    margin: 2rem;
    height: calc(100vh - 4rem - 35px);
    width: calc(100% - 4rem) !important;
    position: relative;
    @include media-breakpoint-up(md) {
      margin: 3rem;
      height: calc(100vh - 6rem - 35px);
      width: calc(100% - 6rem) !important;
    }
    @include media-breakpoint-up(lg) {
      margin: 3rem;
      height: calc(100vh - 6rem - 35px);
      width: calc(100% - 6rem) !important;
    }
    @include element('message') {
      color: white;
      font-size: 1rem;
      font-weight: 300;
      text-align: center;
      line-height: 1.5rem;

      b {
        font-weight: 500;
      }
    }
    @include element('image') {
      width: 100% !important;
      position: absolute !important;
      height: calc(100% - 4rem) !important;
      top: 4rem !important;
      min-height: auto !important;
      bottom: 0px !important;
      z-index: -1 !important;
      object-fit: contain !important;
    }
    @include modifier('first-page') {
      @include media-breakpoint-up(md) {
        width: 56% !important;
        margin: 4rem calc(22%) !important;
      }
      @include media-breakpoint-up(lg) {
        width: 56% !important;
        margin: 4rem calc(22%) !important;
      }
      @include element('message') {
        line-height: auto;
      }
      @include element('image') {
        overflow: hidden;
        border-radius: 1rem;
        top: 2rem !important;
        height: calc(100% - 2rem) !important;
        background-color: white;
        img {
          background-color: white;
        }
      }
    }
  }
  @include block('tutorial-carousel-track-dots') {
    position: fixed;
    height: rem(32px);
    margin: 0;
    padding: 0;
    bottom: rem(16px);
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg) {
      bottom: rem(40px);
    }
    li {
      height: rem(32px);
      width: rem(32px);
      margin: 0;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &.slick-active {
        :first-child {
          background-color: $--color-greyscale-100;
        }
      }
      &:last-child {
        display: none;
      }
    }
  }
  @include block('tutorial-carousel-dot') {
    width: rem(12px);
    height: rem(12px);
    margin: 0;
    padding: 0;
    color: blue;
    border: 1px solid $--color-greyscale-100;
    border-radius: 50%;
  }
  @include block('tutorial-arrow-layout') {
    top: calc(50% - 1rem);
    @include media-breakpoint-up(lg) {
      top: calc(50% - 1rem);
      max-width: none;
    }
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: space-between;
    z-index: 89;
  }
  @include block('tutorial-arrow') {
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: rem(24px);
    height: rem(48px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $--color-greyscale-100;
    font-size: rem(18px);
    @include media-breakpoint-up(md) {
      font-size: rem(32px);
    }
  }
}

@include block('tutorial-first-time') {
  margin: 30vh auto;
  padding: 0 0.6rem;
  max-width: 400px;
  @include element('message') {
    color: $--color-greyscale-100;
    font-weight: 400;
    text-align: center;
    line-height: 1.5rem;
    white-space: nowrap;
    font-style: normal;
    font-size: rem(24px);
  }
  @include element('quest') {
    margin: 0.7rem 0px 0.4rem;
  }
  @include element('tips') {
    color: $--color-greyscale-100;
    font-style: normal;
    font-weight: 300;
    font-size: rem(16px);
    text-align: center;
  }
}
