@include block('menu-divider') {
  &.ant-divider {
    margin-top: rem(24px);
    margin-bottom: rem(16px);
    border-top: 1px solid $--color-greyscale-200;

    @include media-breakpoint-up(lg) {
      margin-top: rem(32px);
      margin-bottom: rem(24px);
    }
  }
}

@include block('menu-footer-link') {
  height: rem(52px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include element('icon-right') {
    display: flex;
    font-size: rem(16px);
  }
}

@include block('wallet-menu') {
  .ant-tabs {
    margin: auto;
  }

  .ant-tabs-nav-wrap {
    margin-top: rem(24px);
    justify-content: center;
  }

  @include modifier('bonus') {
    // background-color: #f0eaff;
  }

  .ant-tabs-nav {
    background-color: $--color-white;
  }

  @include element('loading') {
    display: flex;
    width: 100%;
    height: 436px;
    justify-content: center;
    align-items: center;
  }

  @include element('link') {
    display: block;
    color: $--color-blue-200;
    font-size: rem(20px);
    text-align: center;
    margin: 30px;
  }
}

@include block('wallet-tabs') {
  padding: 0;

  & > .ant-tabs-content-holder {
    padding: 0;
    padding-top: 16px;
    background-color: #fbf7fe;

    .ant-tabs-tab {
      min-width: 45px;
      margin: 0 8px !important;
    }

    .ant-tabs-nav {
      background-color: transparent;
      margin: auto;

      .ant-tabs-nav-operations {
        display: none;
      }

      &::before {
        border-bottom: 2px solid $--color-greyscale-200;
        width: 123px;
        left: 13px;
      }
    }
  }

  &.ant-tabs-card {
    & > .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: 2px solid $--color-primary-1;
      }

      & .ant-tabs-tab {
        color: $--color-greyscale-500;
        background-color: $--color-white;
        border: none;
        padding: 5px 18px;
        border-bottom: 2px solid $--color-primary-1;
        transition: none;

        &.ant-tabs-tab-active {
          border: 2px solid $--color-primary-1;
          border-bottom-color: #fbf7fe !important;
          background-color: #fbf7fe;
          transition: none;
        }
      }

      .ant-tabs-tab-btn {
        color: $--color-greyscale-600;
      }
    }
  }

  .ps-transaction-collapse > .ant-collapse-item {
    margin-top: 5px;
  }
}
