@import 'libs/uikit/src/styles/global';

.container {
  position: relative;
  width: 100%;

  .user-star {
    max-width: 200px;
    width: 72px;
    margin-top: 2px;
  }
  .avatar {
    position: absolute;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
  }
  .action-menu {
    position: absolute;
    top: 0px;
    right: 0%;
    transform: translateX(0%);
    color: var(--color-black);
  }
  .name {
    text-align: center;
    padding-top: 52px;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #2a2a2e;
  }

  .customer-code {
    color: var(--color-greyscale-400);
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }

  .achievement {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 24px;
    margin: 14px auto 0px;
    width: fit-content;

    .img {
      min-width: 80px;
      min-height: 80px;
      border-radius: 50%;
      border: 5px solid var(--color-primary-1);
    }

    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaea;
      border-radius: 50%;
      width: 80px;
      height: 80px;

      .icon {
        display: flex;
        justify-content: center;
        border: 3px dashed var(--color-greyscale-400);
        width: 90%;
        aspect-ratio: 1;
        border-radius: 50%;
        color: var(--color-greyscale-500);
        font-weight: 700;
        // font-size: 9vmin;
        font-size: rem(46px);
      }
    }
  }
}
.tip-button {
  &:global(.ant-btn) {
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
}
