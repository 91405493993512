@include block('register-with-line') {
  @include element('title') {
    height: rem(42px);
    margin: rem(24px) auto rem(16px) auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include element('already-member') {
    height: rem(20px);
    margin: rem(16px) auto rem(48px) auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
