@import 'libs/uikit/src/styles/global';

.line-container {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary-1);
  color: var(--color-primary-1);
  box-shadow: 9984px 0 0 0 var(--color-primary-1),
    9999px 0 0 0 var(--color-primary-1), 10014px 0 0 0 var(--color-primary-1);
  animation: dotTyping 1.5s infinite linear;

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
    33.333% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
    50% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px -10px 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
    66.667% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
    83.333% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px -10px 0 0 var(--color-primary-1);
    }
    100% {
      box-shadow: 9984px 0 0 0 var(--color-primary-1),
        9999px 0 0 0 var(--color-primary-1),
        10014px 0 0 0 var(--color-primary-1);
    }
  }
}

.loading {
  vertical-align: middle;
  width: rem(32px);
  height: rem(64px);
  display: inline-block;
  overflow: hidden;
  background: transparent;
  animation: circle-spin 500ms linear infinite;

  .spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    .body {
      position: absolute;
      width: rem(14px);
      height: rem(14px);
      border: rem(2px) solid var(--color-violet-200);
      border-top-color: transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      box-sizing: content-box;
    }
  }

  @keyframes circle-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
