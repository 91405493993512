%base-arrow-shap {
  height: rem(15px);
  width: rem(15px);
  border: rem(2.5px) solid;
  border-left: none;
  border-bottom: none;
  border-radius: $--border-radius-min;
}

@include block('information-box') {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: rem(8px 8px);
  border: rem(2px) solid;
  border-radius: $--border-radius-min;

  @include modifier('info') {
    border-color: $--color-lightblue-200;
    background-color: $--color-lightblue-100;
  }
  @include modifier('success') {
    border-color: $--color-green-200;
    background-color: $--color-green-100;
  }
  @include modifier('warning') {
    border-color: $--color-yellow-200;
    background-color: $--color-yellow-100;
  }
  @include modifier('danger') {
    border-color: $--color-red-200;
    background-color: $--color-red-100;
  }

  @include element('prepend') {
    padding-left: rem(4px);
    font-size: rem(24px);
    color: $--color-greyscale-600;

    > * {
      width: rem(44px);
      height: rem(44px);
    }
  }
  @include element('content') {
    display: block;
    padding-left: rem(4px);
    padding-right: rem(8px);
    white-space: pre-line;
    line-height: rem(20px);
    text-align: left;

    @include element('title') {
      color: $--color-greyscale-600;
      font-size: rem(20px);
      font-weight: 700;
      margin: 0;
      width: 100%;

      @include modifier('center') {
        text-align: center;
      }
      @include modifier('right') {
        text-align: right;
      }
    }

    @include element('detail') {
      font-size: rem(20px);
      color: $--color-greyscale-500;
      margin: 0;
      width: 100%;
      line-height: rem(20px);
      @include modifier('center') {
        text-align: center;
      }
      @include modifier('right') {
        text-align: right;
      }
    }
  }

  @include element('arrow') {
    @extend %base-arrow-shap;
    position: absolute;

    @include modifier('info') {
      border-color: $--color-lightblue-200;
      background-color: $--color-lightblue-100;
    }
    @include modifier('success') {
      border-color: $--color-green-200;
      background-color: $--color-green-100;
    }
    @include modifier('warning') {
      border-color: $--color-yellow-200;
      background-color: $--color-yellow-100;
    }
    @include modifier('danger') {
      border-color: $--color-red-200;
      background-color: $--color-red-100;
    }
  }
}

/**
* arrow pointer balloon .ant-tooltip-open
*/

.ps-arrow-pointer {
  position: relative;
  cursor: help;
  &:not(.ant-tooltip-open) {
    &::after {
      transition: opacity 100ms ease-out;
      opacity: 0;
    }
  }
}
.ps-arrow-pointer::after {
  transition: opacity 100ms ease-in;
}

.ps-arrow-pointer::after {
  @extend %base-arrow-shap;
  position: absolute;
  content: '';
  z-index: 1100;
}

.ps-arrow-pointer--info::after {
  border-color: $--color-lightblue-200;
  background-color: $--color-lightblue-100;
}
.ps-arrow-pointer--success::after {
  border-color: $--color-green-200;
  background-color: $--color-green-100;
}
.ps-arrow-pointer--warning::after {
  border-color: $--color-yellow-200;
  background-color: $--color-yellow-100;
}
.ps-arrow-pointer--danger::after {
  border-color: $--color-red-200;
  background-color: $--color-red-100;
}

.ps-arrow-pointer--top::after {
  bottom: calc(100% + 6.5px);
  left: 50%;
  transform: translate(-50%, -25%) rotate(135deg) skew(-10deg, -10deg);
}
// TODO : Implement the rest of angle
