@import 'libs/uikit/src/styles/global';

.container {
  width: 100%;

  .bar {
    position: relative;
    width: 100%;
    background-color: #f0eaff;
    border-radius: 8px;
    margin: 4px auto;
    user-select: none;

    .progress {
      background-color: #6729ff;
      height: 8px;
      border-radius: 8px;
      max-width: 100%;
    }

    .label {
      position: absolute;
      bottom: calc(100% + 4px);
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: var(--color-greyscale-500);

      &.left {
        left: 0px;
      }
      &.right {
        right: 0px;
      }
    }
  }
}
