@include block('hero-game-detail') {
  position: relative;
  background-color: #f7f7f7;

  @include media-breakpoint-between(xs, md) {
    height: rem(581px);
    overflow: hidden;
  }

  @include media-breakpoint-between(md, xl) {
    padding-bottom: rem(28px);
  }

  @include media-breakpoint-up(xl) {
    height: rem(598px);
  }

  @include element('cover') {
    position: absolute;
    top: rem(-82px);
    left: 50%;
    margin-right: -50%;
    width: rem(598px);
    height: rem(598px);
    transform: translate(-50%, 0);
  }

  @include element('content') {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: rem(76px);
    padding-bottom: rem(32px);
    width: 100%;
    height: auto;
    max-height: rem(434px);
    background: linear-gradient(
      180deg,
      rgba(246, 246, 246, 0) 0%,
      #f6f6f6 47.4%
    );

    @media (min-width: 320px) and (max-width: 374.98px) {
      padding-top: rem(30px);
    }
  }

  @include element('img-container') {
    width: rem(156px);
    height: rem(156px);
  }

  @include element('title') {
    margin-top: rem(-6px);
    margin-bottom: rem(16px);
    color: $--color-greyscale-600;
    font-size: rem(50px);
    font-weight: 900;
    line-height: 1;

    @media (min-width: 320px) and (max-width: 374.98px) {
      margin-top: rem(8px);
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: rem(24px);
      max-height: none;
      font-size: rem(96px);
    }
  }

  @include element('btn') {
    width: 100%;
    height: 100%;
  }
}
