@import 'libs/uikit/src/styles/global';

.container {
  min-height: calc(100vh - 360px);

  @include media('>sm') {
    min-height: calc(100vh - 375px);
  }

  @include media('>lg') {
    min-height: calc(100vh - 210px);
  }
}
