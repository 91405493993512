@include block('profile-tabs') {
  padding: 0;

  & > .ant-tabs-content-holder {
    padding: 0;
    background-color: #fbf7fe;

    .ant-tabs-tab {
      min-width: 45px;
      margin: 0 8px !important;
    }

    .ant-tabs-nav {
      background-color: transparent;
      margin: auto;

      .ant-tabs-nav-operations {
        display: none;
      }

      &::before {
        border-bottom: 2px solid $--color-greyscale-200;
        width: 123px;
        left: 13px;
      }
    }
  }

  &.ant-tabs-card {
    & > .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: 2px solid $--color-primary-1;
      }

      & .ant-tabs-tab {
        color: $--color-greyscale-500;
        background-color: $--color-white;
        border: none;
        padding: 5px 18px;
        border-bottom: 2px solid $--color-primary-1;
        transition: none;

        &.ant-tabs-tab-active {
          border: 2px solid $--color-primary-1;
          border-bottom-color: #fbf7fe !important;
          background-color: #fbf7fe;
          transition: none;
        }
      }

      .ant-tabs-tab-btn {
        color: $--color-greyscale-600;
      }
    }
  }

  .ps-transaction-collapse > .ant-collapse-item {
    margin-top: 5px;
  }
}
