@include block('menu') {
  padding: 0px;
}

@include block('menu-section') {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include media-breakpoint-up('md') {
    margin: 16px 55px;
  }

  @include element('item') {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-size: rem(20px);
    cursor: pointer;
    &:last-child {
      margin-left: -6px;
    }

    @include element('menu-badge') {
      position: relative;
      margin: 0 14px;
      @include media-breakpoint-up('lg') {
        margin: 0 30px;
      }
      .ps-notification-badge {
        top: -5px;
        border-color: #fcfcfc;
      }
    }
    @include element('menu-title') {
      margin-top: 0px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      font-style: normal;
      line-height: 2ch;
      color: $--color-greyscale-500;
      margin-top: 12px;
      @include media-breakpoint-up('md') {
        font-size: 22px;
      }
    }
  }
}
