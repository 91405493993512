@include block('promotion-card-section') {
  position: relative;
  color: $--color-black;
  display: block;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: rem(36px);
  margin-bottom: rem(4px);
  width: 100%;

  @include element('wrapper') {
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  @include element('card-list') {
    text-align: center;
  }

  @include element('item') {
    position: sticky;
    will-change: transform;
    top: rem(84px);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-radius: rem(24px);

    @include media-breakpoint-down('sm') {
      max-height: rem(490px);
    }
  }

  @include element('item-desktop') {
    width: 100%;
    height: 100%;
    margin-top: rem(24px);
    border-radius: rem(24px);
    cursor: pointer;
  }

  @include element('image-container') {
    display: block;
    padding: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;

    img {
      border-radius: rem(24px);
    }
  }
}

@include block('promotion-card-section-headline') {
  font-size: rem(95.94px) !important;

  h2 {
    line-height: 1;
  }

  @include element('first-line') {
    color: $--color-orange-400;
    margin: 0;
    text-align: center;
  }

  @include element('second-line') {
    margin: 0;
    text-align: center;
    @include media-breakpoint-up('lg') {
      display: none;
    }

    @include modifier('desktop') {
      display: none;

      @include media-breakpoint-up('lg') {
        display: block;
      }
    }
  }
}
