@include block('game-detail-register') {
  display: none;
  margin-top: rem(40px);
  margin-bottom: rem(40px);

  @include modifier('show') {
    display: block;
  }

  @include element('btn') {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      margin: auto;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
    }
  }
}
