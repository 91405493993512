@include block('leaderboard-game-list') {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: rem(32px);
  padding-bottom: rem(32px);

  @include media-breakpoint-up(md) {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
  }

  @include element('btn') {
    padding: rem(12px 16px);
    font-size: rem(24px);
    font-weight: 900;
    line-height: 1;

    @include media-breakpoint-up(lg) {
      width: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
