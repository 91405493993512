@include block('hamburger') {
  $--space-for-header: 64px;
  $--hamburger-padding: 16px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0px;
  display: block;
  width: rem(304px);
  height: 100vh;
  padding: rem(28px 4px);
  padding-bottom: rem(96px);
  background-color: $--color-violet-100;
  overflow: hidden scroll;
  z-index: 123;
  height: 100vh;
  max-height: calc(100vh);
  padding-top: $--hamburger-padding;
  padding-bottom: $--hamburger-padding;
  scrollbar-width: none;

  @include media-breakpoint-up('md') {
    top: $--space-for-header;
    height: auto;
    max-height: calc(100vh - 47px);
    z-index: 89; // under Header component for dropdown menu version
  }
  &::-webkit-scrollbar {
    width: 0;
  }

  @include element('head') {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-flow: row nowrap;
    gap: rem(0 8px);
    padding-left: rem(16px);
    padding-right: rem(16px);
    padding-bottom: rem(8px);
    margin: 0;

    @include element('avatar-icon') {
      flex-grow: initial;
      cursor: pointer;
    }

    @include element('content') {
      flex-basis: 200px;

      @include element('name') {
        display: block;
        font-size: rem(20px);
        line-height: rem(20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: 25ch;
      }

      @include element('money') {
        display: inline-flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        > strong {
          font-size: rem(24px);
          line-height: rem(24px);
          margin-left: rem(8px);
          user-select: none;
        }
      }

      @include element('kyc') {
        display: flex;
        cursor: pointer;
        font-size: rem(18px);
        line-height: rem(18px);
        i {
          font-size: rem(12px);
        }
      }
    }

    @include element('close-icon') {
      flex-grow: initial;
      line-height: rem(18px);
      font-size: rem(18px);
      margin-top: rem(6px);
      cursor: pointer;
      transition: transform 250ms ease-in-out;
      transform-origin: center;

      &:focus,
      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  @include element('list') {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    font-weight: 500;
    overflow: hidden;
    padding: 0;
    margin-bottom: 15vh;
    @include media-breakpoint-up('md') {
      margin-bottom: 0vh;
    }

    @include element('item') {
      font-size: rem(24px);
      line-height: rem(24px);
      height: rem(48px);
      font-weight: 400;

      @include modifier('divider') {
        border: none;
        height: 1px;
        background-color: $--color-greyscale-500;
        margin: rem(8px 16px);
        padding: 0;
      }

      @include modifier('special') {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: fit-content;
        padding: rem(0 8px);
        margin: rem(8px 0);
        gap: rem(8px);
      }
    }

    @include element('link') {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      color: $--color-greyscale-600;
      transition: color, background-color 300ms ease-in-out;
      padding: rem(1px 28px);
      font-size: 24px;
      white-space: nowrap;
      animation: flashingMenu 0.3s 0.8s 2 alternate-reverse ease-in-out;
      animation-play-state: running;

      @keyframes flashingMenu {
        0% {
          background-color: $--color-violet-200;
        }
        100% {
          background-color: $--color-violet-100;
        }
      }

      &:active,
      &:focus {
        background-color: $--color-violet-200;
      }

      i {
        margin-top: rem(-4px);
        font-size: rem(20px);
      }
    }

    @include element('link-icon') {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      width: 36px;
      height: 36px;
      img {
      }
      position: relative;
    }

    @include element('link-text') {
      display: inline-block;
      margin-left: 1.8ch;
    }

    @include element('special-link') {
      display: inline-flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: rem(75px);
      width: rem(132px);
      color: $--color-greyscale-600;
      border-radius: $--border-radius-1;
      transition: all 300ms ease-out;

      > *:first-child {
        transform: scale(1.2);
      }

      & > i::before {
        font-size: rem(20px);
      }

      @include modifier('masked') {
        box-shadow: $--box-shadow-1;
        color: $--color-violet-100;
        background-color: $--color-violet-400;

        &:active,
        &:focus {
          background-color: $--color-violet-600;
        }
      }

      i {
        font-size: rem(30px);
        padding-bottom: rem(4px);
      }
    }
  }
}
