@include block('header') {
  top: 0;
  width: 100%;
  box-shadow: $--box-shadow-1;
  height: 64px;
  z-index: 90;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $--color-violet-100;
  animation: transition-delay 0.1s 0.5s forwards;
  @include modifier('affiliate') {
    background-color: $--color-yellow-200;
  }
  @keyframes transition-delay {
    0% {
      transition: background-color 0s;
    }
    100% {
      transition: background-color 0.3s;
    }
  }
  @include media-breakpoint-down('sm') {
    > .container-fluid {
      padding: 12px;
    }
  }
  @include modifier('landing') {
    background-color: #efe6fd00;
    box-shadow: none;
  }
  @include element('container') {
    max-width: none;
  }
  @include element('chat-channel-selector') {
    color: $--color-greyscale-600;
    min-width: 70px;
    display: flex;
    justify-content: flex-end;
  }
  @include element('back-button') {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $--color-greyscale-600;
    padding: 4px;
    cursor: pointer;

    &:hover {
      animation: backbutton 0.4s ease-in;
      @keyframes backbutton {
        0% {
          color: $--color-primary-1;
          transform: scale(1);
        }
        40% {
          color: $--color-primary-1;
          transform: scale(0.8);
        }
        100% {
          color: $--color-greyscale-600;
          transform: scale(1);
        }
      }
    }

    @include media-breakpoint-up('lg') {
      display: none;
    }

    i {
      font-size: rem(18px);
    }
  }

  @include element('btn-bold') {
    font-weight: bold !important;
  }

  @include element('logo') {
    width: rem(124px);
    height: auto;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;

    @include media-breakpoint-up('sm') {
      width: rem(124px);
    }

    &--smaller {
      width: rem(124px);
      margin-left: rem(27px);
    }

    @include media-breakpoint-up('lg') {
      width: rem(150px);
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @include element('landing-button') {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @include element('navbar') {
    position: relative;
    align-items: center;
    margin-left: auto;
    display: flex;
    transition: opacity color 0.3s;

    @include modifier('no-margin') {
      margin: initial;
    }

    .loading-spinner {
      vertical-align: middle;
      width: rem(32px);
      height: rem(64px);
      display: inline-block;
      overflow: hidden;
      background: transparent;
    }

    .spinner-ring {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
    }

    .spinner-ring div {
      position: absolute;
      width: rem(14px);
      height: rem(14px);
      border: rem(2px) solid $--color-violet-200;
      border-top-color: transparent;
      border-radius: 50%;
      animation: circle-spin 500ms linear infinite;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .spinner-ring div {
      box-sizing: content-box;
    }

    &-hamberger {
      display: flex;
      width: rem(48px);
      height: rem(48px);
      transform: translateX(rem(10px));
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      background: url('/images/hamburger-icon.svg') 60% no-repeat;
      background-size: 25px;
      margin-top: -4px;
      &--focus {
        background: url('/images/hamburger-icon_hover.svg') 60% no-repeat;
        background-size: 25px;
        margin-top: -4px;
      }
      position: relative;
      .ps-notification-badge {
        top: 0.3rem;
        right: 0.15rem;
      }
    }

    &--hidden-on-mobile {
      display: none !important;
      @include media-breakpoint-up('md') {
        display: initial !important;
      }
    }

    &-boundary {
      margin: 0 rem(10px);
    }

    &-text {
      font-size: $--font-size-b1;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $--color-primary-1;
      }
    }

    &-avartar {
      margin: 0 12px;
      cursor: pointer;
    }

    &-amount {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 0.3rem;
      }
      &__total_amount {
        font-size: $--font-size-b1;
      }
    }

    &-chat {
      display: flex;
      transform: translateX(6px);
      user-select: none;
      cursor: pointer;

      svg {
        font-size: 24px;
      }
      &--focus {
        color: $--color-primary-1;
      }
      &--landing {
        margin-right: 16px;
      }
      &--white {
        color: #fff;
      }
    }
  }
}

@include block('header-hamburger-menu') {
  z-index: 30;
  @include modifier('desktop-version') {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
  }
}
