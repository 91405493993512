@include block('game-detail-menu-tabs') {
  padding: 0px;
}
@include block('game-detail-copy') {
  margin-bottom: rem(16px);
  font-size: rem(20px);
  line-height: 1.2;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    margin-bottom: rem(32px);
  }

  @include element('logo') {
    margin-bottom: rem(32px);

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }
  @include element('logo-container') {
    width: rem(156px);
    height: rem(156px);
  }

  @include element('title') {
    margin: 1ch 0;
    font-weight: 900;
    line-height: 1;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(8px);
      font-size: rem(48px);
    }
  }

  @include element('wrapper') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: rem(16px);
    line-height: 1.2;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }

  @include element('article_wrapper') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: rem(16px);
    line-height: 1.2;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      margin-bottom: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(16px);
    }
  }

  @include element('content') {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      display: inline;
      line-height: 1.2;
      word-break: break-word;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);
      }
    }
  }

  @include element('tag_wrapper') {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    margin-bottom: -53px;
  }

  @include element('tag') {
    background-color: #e73812;
    color: $--color-white;
    border-radius: 9px;
    width: fit-content;
    padding: 0 10px;
    font-size: rem(16px);

    @include media-breakpoint-up(lg) {
      font-size: rem(20px);
    }
  }

  @include element('article') {
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      display: inline;
      line-height: 1.2;
      word-break: break-word;

      @include media-breakpoint-up(xl) {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);
      }
    }
  }

  @include element('btn') {
    display: inline-block;
    margin-top: rem(8px);
    padding-right: 0;
    padding-left: 0;
    font-weight: 400;

    span {
      text-decoration: underline;
      text-underline-offset: 1px;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 1px;
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  @include element('line') {
    margin: 0;
    margin-top: 30px;
    opacity: 1;
    background-color: $--color-greyscale-200;
  }

  @include modifier('expanded') {
    @include element('content') {
      -webkit-line-clamp: initial;

      p {
        display: block;
        margin-top: 0;
        margin-bottom: rem(16px);

        @include media-breakpoint-up(md) {
          margin-bottom: rem(32px);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
