@import 'libs/uikit/src/styles/global';

.radio-group {
  span {
    font-size: rem(20px);
  }
}

.radio {
  gap: 4px;

  :global(.ant-radio-inner) {
    background-color: transparent !important;
    outline: none;

    &::after {
      background-color: var(--color-primary-1);
      width: 26px;
      height: 26px;
      margin-block-start: -13px;
      margin-inline-start: -13px;
    }
  }
}
