@include block('notification-badge') {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c8373e;
  border: 2px solid #efe6fd;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 12;
}
