@include block('hero-space') {
  margin-top: -64px;
  margin-bottom: -10px;
  overflow: hidden;
  user-select: none;
  height: calc(70vh);
  @include media-breakpoint-up(md) {
    height: calc(70vh);
  }

  $--space-intro--duration: 3s;
  $--globe-size: 300px;
  $--globe-size-xl: 400px;
  z-index: -1;

  @include element('space') {
    z-index: 0;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  @include element('globe') {
    z-index: 2;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    animation: glowing-globe 1s infinite alternate ease-in-out;
    @keyframes glowing-globe {
      0% {
        filter: brightness(1) saturate(1.3);
      }
      100% {
        filter: brightness(1.2) saturate(1);
      }
    }
  }
  @include element('globe-v2') {
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    left: 50%;
    width: $--globe-size;
    height: $--globe-size;
    transform: translate(-50%, -50%);
    aspect-ratio: auto 100 / 100;

    @include media-breakpoint-up(md) {
      transform: translate(-50%, -50%) scale(1);
      width: $--globe-size-xl;
      height: $--globe-size-xl;
    }

    @include element('globe-canvas') {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      width: $--globe-size;
      height: $--globe-size;
      mask-image: radial-gradient(
        circle at top left,
        #f00 40%,
        transparent 100%
      );
      filter: brightness(3) saturate(0.2) contrast(2.5);
      @include media-breakpoint-up(md) {
        width: $--globe-size-xl;
        height: $--globe-size-xl;
      }
    }
    @include element('globe-flare') {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('/images/landing/Aura-top.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 4;
      opacity: 0.4;
      transform: scale(1);
    }
    @include element('globe-shadow') {
      position: absolute;
      width: 130%;
      height: 130%;
      background-image: url('/images/landing/Aura-under.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 3;
      opacity: 0.8;
    }
  }

  @include element('planet') {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 0;
    left: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    @include media-breakpoint-up(md) {
      transform: scale(2);
    }
    @include modifier('1') {
      background-image: url('/images/landing/planet_3.png');
      top: 6%;
      left: 76%;
      @include media-breakpoint-up(md) {
        top: -14%;
        left: 65%;
      }
    }
    @include modifier('2') {
      background-image: url('/images/landing/planet_1.png');
      top: 25%;
      left: 85%;
      @include media-breakpoint-up(md) {
        top: 30%;
        left: 85%;
      }
    }
    @include modifier('3') {
      background-image: url('/images/landing/planet_5.png');
      top: 55%;
      left: 75%;
    }
    @include modifier('4') {
      background-image: url('/images/landing/planet_4.png');
      top: 51%;
      left: -10%;
    }
    @include modifier('5') {
      background-image: url('/images/landing/planet_2.png');
      top: 14%;
      left: -15%;
      @include media-breakpoint-up(md) {
        left: 9%;
      }
    }
  }

  $--ship-enter-speed: 3.5s;
  $--ship-darkness: 30%;
  $--ship-brightness: 200%;
  $--animation-loop: infinite;
  $--animation-loop: forwards;
  @include element('ship') {
    position: absolute;
    top: 70%;
    left: 50%;
    width: 300px;
    aspect-ratio: 500/246;
    z-index: 4;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include modifier('body') {
      background-image: url('/images/landing/ship.png');
      animation: ship-lighting $--ship-enter-speed $--animation-loop
        cubic-bezier(0.11, 1.26, 0.38, 1.2);
      @keyframes ship-lighting {
        0%,
        99% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
    @include modifier('trail') {
      background-image: url('/images/landing/ship-trail.png');
      background-position: center;
      background-size: 100% 100%;
      transform: translate(-50%, -200px);
      width: 440px;
      height: 240px;
      z-index: 2;
      mask-size: 100% 80%;
      -webkit-mask-repeat: no-repeat;
      mask-image: linear-gradient(0deg, transparent 0%, black 100%);
      mask-position: center 240px;
      animation: ship-trailing 4s $--ship-enter-speed
        cubic-bezier(0, 0.78, 0.33, 0.85);
      @keyframes ship-trailing {
        0% {
          mask-position: center 240px;
          filter: saturate(20);
        }
        100% {
          mask-position: center -240px;
          filter: saturate(1);
        }
      }
    }
    @include modifier('booster') {
      background-image: url('/images/landing/ship-booster.png');
      mask-size: 50%;
      mask-image: linear-gradient(
        0deg,
        rgba(100, 100, 100, 0.6474964985994398) 6%,
        rgba(0, 0, 0, 1) 16%,
        rgba(112, 112, 112, 0.6530987394957983) 17%,
        rgba(0, 0, 0, 1) 35%,
        rgba(128, 128, 128, 0.6446953781512605) 37%,
        rgba(0, 0, 0, 1) 53%,
        rgba(121, 121, 121, 0.6334908963585435) 54%,
        rgba(0, 0, 0, 1) 63%,
        rgba(121, 121, 121, 0.5858718487394958) 65%,
        rgba(0, 0, 0, 1) 80%,
        rgba(121, 121, 121, 0.5830707282913166) 82%,
        rgba(0, 0, 0, 1) 93%,
        rgba(121, 121, 121, 0.5298494397759104) 94%,
        rgba(121, 121, 121, 0.5382528011204482) 100%
      );
      mask-size: 100% 300%;
      animation: running-booster 0.5s infinite linear;
      filter: brightness(100%) saturate(100%);
      @keyframes running-booster {
        0% {
          mask-position: center 100%;
          opacity: 1;
        }
        50% {
          mask-position: center 50%;
          opacity: 0.7;
        }
        100% {
          mask-position: center 0%;
          opacity: 1;
        }
      }
    }
    @include modifier('warping') {
      animation: ship-warping $--ship-enter-speed $--animation-loop
        cubic-bezier(0.11, 1.26, 0.38, 1.2);
      @keyframes ship-warping {
        0%,
        99% {
          opacity: 1;
          margin-bottom: 0px;
          filter: brightness(100%);
        }
        100% {
          opacity: 0;
          margin-bottom: 16px;
          filter: brightness(300%);
        }
      }
    }
  }
  @include element('ship-movement') {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    animation: ship-movement $--ship-enter-speed $--animation-loop
      cubic-bezier(0.96, -0.07, 1, 1);
    @keyframes ship-movement {
      0% {
        transform: translateY(10vh) scale(2);
        filter: brightness(0%) blur(4px);
      }
      100% {
        transform: translateY(0vh) scale(1);
        filter: brightness(100%) blur(0px);
      }
    }
  }
  $--ring-speed-raw: 30;
  $--ring-speed: #{$--ring-speed-raw}s;
  @include element('ring') {
    position: absolute;
    display: flex;
    top: 45%;
    left: 50%;
    width: 500px;
    height: 500px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: translate(-50%, -50%) rotate(-16deg) scale(1.2);
    @include media-breakpoint-up(md) {
      transform: translate(-50%, -50%) rotate(-16deg) scale(1.9);
    }
    z-index: 3;

    ul {
      margin: 0;
      list-style: none;
      font-size: 32px;
      transform-style: preserve-3d;
      animation: three-dimensions-circle $--ring-speed linear infinite;
    }
    li {
      position: absolute;
      top: 50%;
      left: 50%;
      color: white;
      opacity: 0.2;
      text-shadow: 0 0 6px #7900ff;
      animation: behind-globe $--ring-speed infinite;

      @keyframes behind-globe {
        0%,
        10%,
        75%,
        100% {
          z-index: 3;
          opacity: 0;
          filter: brightness(1) saturate(1);
        }
        20%,
        65% {
          z-index: 1;
          opacity: 1;
          filter: brightness(1.5) saturate(1.5);
        }
      }
    }
    @for $i from 1 through 36 {
      li:nth-child(#{$i}) {
        transform: translate(-50%, -50%)
          rotate(#{$i * 10 - 30}deg)
          translateY(-110px)
          rotateX(90deg);
        animation-delay: #{calc(($i * ($--ring-speed-raw / 36 * -1))) -
          calc($--ring-speed-raw * 0.33)}s;
      }
    }
    @keyframes three-dimensions-circle {
      0% {
        transform: rotateX(-100deg) rotate(-360deg);
      }
      100% {
        transform: rotateX(-100deg) rotate(0deg);
      }
    }
  }
  @include element('text') {
    position: absolute;
    width: 100%;
    left: 50%;
    color: $--color-greyscale-100;
    font-weight: 900;
    text-align: center;
    transform: translateX(-50%);
    z-index: 5;
    font-size: 42px;
    bottom: 17%;
    line-height: 2.5ch;
    @include media-breakpoint-up(md) {
      font-size: 64px;
      line-height: 64px;
    }
  }
}

@include block('space-dust') {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  transform: scaleX(2);
  z-index: 1;
}
@include block('space-stars') {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgb(61 53 124 / 67%) 0%,
    rgb(23, 6, 48) 40%
  );
  canvas {
    background-size: 100% 90%;
  }
}

@include block('space-flare') {
  position: absolute;
  width: 180%;
  z-index: 4;
  height: 180%;
  filter: hue-rotate(240deg) saturate(7) blur(1px);
  mask-image: radial-gradient(
    ellipse 35% 35% at 50% 50%,
    transparent 50%,
    black 65%,
    transparent 150%
  );
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;

  .lens-center {
    position: relative;
    width: 40vmin;
    height: 40vmin;
    border-radius: 100%;
    left: calc(35% - (40vmin / 2));
    top: calc(35% - (40vmin / 2));
    background: radial-gradient(
      closest-side circle at center,
      hsl(4 5% 100% / 100%) 0%,
      hsl(4 5% 100% / 100%) 15%,
      hsl(4 10% 70% / 70%) 30%,
      hsl(4 0% 50% / 30%) 55%,
      hsl(4 0% 10% / 5%) 75%,
      transparent 99%
    );
    filter: blur(4px);
  }
  .lens-center::before {
    content: '';
    display: block;
    width: 80vmin;
    height: 80vmin;
    left: calc((80vmin - 40vmin) / 2 * -1);
    top: calc((80vmin - 40vmin) / 2 * -1);
    position: absolute;
    border-radius: 100%;
    background: radial-gradient(
      closest-side circle at center,
      hsl(4 15% 70% / 15%) 0%,
      transparent 100%
    );
  }

  .lens-center::after {
    content: '';
    display: block;
    width: 4vmin;
    height: 4vmin;
    left: 65%;
    bottom: 25%;
    position: absolute;
    border-radius: 100%;
    background: radial-gradient(
      closest-side circle at center,
      hsl(4 30% 70% / 60%) 0%,
      transparent 75%
    );
  }

  .circle-1 {
    width: calc(40vmin * 0.7);
    height: calc(40vmin * 0.7);
    left: 65%;
    top: 65%;
    border-radius: 100%;
    position: absolute;
    background: radial-gradient(
      closest-side circle at center,
      transparent 50%,
      hsl(4 10% 70% / 40%) 90%,
      transparent 100%
    );
    filter: blur(5px);
  }

  .circle-2 {
    width: calc(40vmin * 0.4);
    height: calc(40vmin * 0.4);
    left: 62%;
    top: 62%;
    border-radius: 100%;
    position: absolute;
    background: hsl(4 10% 60% / 40%);
    filter: blur(2px);
  }

  .horizontal-flare {
    position: absolute;
    height: 5vh;
  }

  .right-flare {
    left: 35%;
    width: calc(100vw - 35%);
    top: calc(35% - (5vh / 2));
    background: radial-gradient(
      ellipse at center left,
      hsl(4 20% 90% / 80%) 0%,
      hsl(4 10% 70% / 40%) 30%,
      transparent 75%
    );
    filter: opacity(50%);
  }

  .left-flare {
    left: 0;
    top: calc(35% - (5vh / 2));
    width: 35%;
    background: radial-gradient(
      ellipse at center right,
      hsl(4 20% 90% / 60%) 0%,
      hsl(calc(4 + 10) 10% 70% / 40%) 30%,
      transparent 75%
    );
    filter: opacity(40%);
  }

  .full-flare {
    background: radial-gradient(
      closest-side ellipse at 45% center,
      hsl(4 20% 90% / 80%) 0%,
      hsl(4 10% 70% / 30%) 30%,
      transparent 95%
    );
    left: 0%;
    width: 100%;
    top: 75vh;
    filter: blur(5px);
  }
}
