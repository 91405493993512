@include block('stepper') {
  width: 100%;
  height: 100%;
  position: relative;

  @include element('step-wrapper') {
    width: 1000px;
    height: 100%;
  }
  @include element('stepper-selector') {
    position: absolute;
    height: 100%;
    display: inline-flex;
    top: 0;
  }
  @include element('stepper-progress') {
    top: 15px;
    width: 100%;
    z-index: 9;
  }
  @include element('stepper-progress-wrapper') {
    width: 100%;
    position: relative;
    display: flex;
    margin: auto;
    justify-content: space-between;
  }
  @include element('stepper-progress-bar') {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 80%;
    z-index: -1;
    background: $--color-violet-200;
    @include media-breakpoint-up(lg) {
      width: 80%;
      left: 108px;
    }
  }
  @include element('step-title') {
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: $--font-size-base;
    color: $--color-greyscale-600;
    height: 30px;
    // background: #fff;
  }
  @include element('step-subtitle') {
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    white-space: pre-wrap;
    @include media-breakpoint-up(lg) {
      width: 170px;
    }
  }
  @include element('step-title-number') {
    font-size: 24px;
    color: $--color-greyscale-100;
    font-weight: normal;
    background: $--color-violet-200;
    height: 48px;
    width: 48px;
    margin: auto;
    line-height: 2;
    border-radius: 100%;
    box-shadow: 0 0 0 6pt #ffffff;

    @include modifier('current') {
      background: $--color-violet-400;
    }
    @include modifier('completed') {
      font-size: 18px;
      color: $--color-violet-400;
      background-color: white;
      line-height: 3;
      border: 2px solid $--color-violet-400;
    }
  }
}
