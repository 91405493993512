@include block('landscape-warning') {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $--color-violet-500;
  overflow: hidden;
  z-index: 99999;

  @include modifier('show') {
    display: flex;
  }

  @include element('toast') {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 2px solid $--color-lightblue-200;
    padding: rem(16px);
    font-size: rem(20px);
    line-height: 1;
    background-color: $--color-lightblue-100;
    animation-name: fadeIn;
    animation-duration: 0.6s;

    .ps-icon-caution {
      color: $--color-lightblue-200;
    }
  }

  @include element('message') {
    margin-left: rem(12px);
  }
}
