// Var
$--var--icon-size: 20px;
$--var--icon-size-lg: 27px;

@include block('progress-bar') {
  position: relative;
  width: 100%;
  background-color: $--color-greyscale-100;
  border-radius: rem(12px);
  color: $--color-greyscale-600;
  font-size: 16.5px;
  height: rem(16px);
  text-align: center;
  overflow: hidden;
  user-select: none;

  @include element('progress') {
    height: 100%;
    border-radius: inherit;
    transition-property: width, background-color;
    display: flex;
    align-items: center;
  }

  @include element('label') {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 1.1;
    transition: 0.8s ease;
  }
  @include modifier('mainquest') {
    height: $--var--icon-size - 2;
    @include media-breakpoint-up('lg') {
      height: $--var--icon-size-lg - 2px;
    }
  }
}

// Progress Bar
@include block('progress-bar-section') {
  position: relative;
  @include element('icon-ct') {
    position: absolute;
    top: rem(-1px);
    left: rem(-5px);
    @include element('checked-icon') {
      position: absolute;
      color: $--color-orange-400;
      z-index: 2;
      font-size: $--var--icon-size;
      @include media-breakpoint-up('lg') {
        font-size: $--var--icon-size-lg;
      }

      @include modifier('filled') {
        position: absolute;
        background-color: white;
        top: calc(0.1 * $--var--icon-size);
        left: calc(0.1 * $--var--icon-size);
        width: calc(0.8 * $--var--icon-size);
        height: calc(0.8 * $--var--icon-size);
        @include media-breakpoint-up('lg') {
          top: calc(0.1 * $--var--icon-size-lg);
          left: calc(0.1 * $--var--icon-size-lg);
          width: calc(0.8 * $--var--icon-size-lg);
          height: calc(0.8 * $--var--icon-size-lg);
        }
        z-index: 1;
        border-radius: 50%;
      }
    }
  }
}
