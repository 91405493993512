@include block('dailyquests-card') {
  margin: 0 0 rem(16px) rem(8px);
  padding: rem(4px) rem(16px) rem(8px) rem(24px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: rem(8px);
  box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
  background-color: #decbfb;
  transition: 0.8s ease-out;
  min-height: 124px;

  @include modifier('done') {
    background-color: $--color-green-100;
  }
  @include modifier('error') {
    background-color: $--color-danger;
  }
  @include modifier('claimed') {
    background-color: $--color-green-100;
    opacity: 0.5;
  }
  @include modifier('error') {
    background-color: $--color-red-100;
  }
  @include modifier('skeleton') {
    height: 124px !important;
    width: 100% !important;
    display: block !important;
    margin-bottom: 16px;
    // border: 1px solid red !important;
  }

  @include element('top') {
    padding-left: 42px;
    width: 100%;
    @include element('logo') {
      position: absolute;
      width: 48px;
      height: 48px;
      left: -8px;
      top: -8px;

      background: transparent;
      border-radius: 8px;

      @include modifier('claimed') {
        background-color: $--color-green-100;
        opacity: 0.5;
      }
      @include modifier('error') {
        background-color: $--color-red-100;
      }

      @include element('bigwin') {
        background-color: $--color-white;
        border: 1px solid $--color-greyscale-300;
        border-radius: 8px;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ps-game-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 9px;
        }
      }
    }
    @include element('logo-other') {
      position: absolute;
      width: 48px;
      height: 48px;
      left: -8px;
      top: -8px;

      padding: 8px;
      background: $--color-violet-600;
      border-radius: 8px;
    }
    @include element('title') {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: $--color-greyscale-600;
      margin: 4px 0px;
    }
    @include element('subtitle') {
      margin: 4px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: rem(16px);
      color: $--color-greyscale-400;
      @include modifier('bigwin') {
        color: $--color-greyscale-400;
        margin: 4px 0px;
      }
    }
    @include element('progress-bar') {
      margin: 8px 0 8px 0;
    }
  }

  @include element('bottom') {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include element('icon') {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    @include element('tickets') {
      font-style: normal;
      font-weight: normal;
      font-size: rem(16px);
      line-height: rem(16px);
      display: flex;
      align-items: center;
      color: $--color-violet-400;

      @include modifier('done') {
        color: $--color-green-200;
      }
      @include modifier('error') {
        color: $--color-danger;
      }
      @include modifier('claimed') {
        color: $--color-green-200;
      }

      > i {
        font-size: rem(24px);
        margin-right: rem(4px);
      }
    }
    @include element('button') {
      font-style: normal;
      font-weight: normal;
      font-size: rem(20px);
      line-height: rem(20px);
      padding: 0.9ch 1.5ch;
      min-width: 16ch;

      @include modifier('done') {
        background-color: $--color-green-200;
      }
      @include modifier('error') {
        background-color: $--color-danger;
      }
      @include modifier('claimed') {
        background-color: transparent;
        color: $--color-green-200;
        opacity: 1;
        box-shadow: none;
      }
      @include element('file-upload') {
        display: none;
      }
    }
  }
}
@include block('bigwin-card-tutorial') {
  margin: 0 0 rem(16px) rem(8px);
  padding: rem(4px) rem(16px) rem(8px) rem(24px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: rem(8px);
  box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
  background-color: #decbfb;
  transition: 0.8s ease-out;

  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  @include element('top') {
    padding-left: 40px;
    width: 58%;
    @include element('avatar') {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 64px;
      bottom: -8px;
      @include element('avatar-img') {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem(8px);
      }
    }
    @include element('title') {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: $--color-greyscale-600;
      margin: 4px 0px;
      padding-bottom: 1em;
    }
  }
  @include element('bottom') {
    padding-left: 0px;
    width: 14ch;
    min-width: auto;
    display: flex;
    justify-content: flex-end;
  }

  @include element('button') {
    width: 100%;
  }
}
