@include block('empty-menu-layout') {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: rem(20px);
}
@include block('empty-image') {
  min-height: rem(94px);
  @include media-breakpoint-up(md) {
    min-height: rem(128px);
  }
}
@include block('empty-title') {
  margin-top: rem(24px);
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: $--color-greyscale-600;
}
@include block('empty-subtitle') {
  color: $--color-greyscale-400;
}
@include block('empty-button') {
  margin: rem(24px) auto auto auto;
  @include media-breakpoint-up(lg) {
    margin: rem(24px) auto rem(8px) auto;
  }
}
