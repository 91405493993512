@import 'libs/uikit/src/styles/global';

.container {
  width: auto;

  .name {
    display: block;
    width: 100%;
    max-width: 25ch;
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2a2a2e;
    margin-left: 3px;
  }

  .tier {
    display: flex;
    padding: 8px 0px;
    margin-top: 3px;
    min-width: 200px;
  }

  .next-level {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-greyscale-500);
  }

  .vip-tag {
    position: relative;
    top: 0px;
  }
}
