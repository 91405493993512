@include block('champion-footer-hidden') {
  opacity: 0.3;
  display: none;
}
@include block('champion-header') {
  position: relative;
  margin-top: rem(16px);
  width: 100%;
  height: rem(136px);
  background-color: $--color-greyscale-200;
  border-radius: rem(8px);
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@include block('champion-label') {
  margin-top: rem(16px);
  margin-bottom: rem(18px);
  font-style: normal;
  font-weight: 900;
  font-size: rem(34.56px);
  line-height: rem(35px);
  color: $--color-greyscale-600;
}

@include block('champion-cards') {
  user-select: none;
  @include modifier('fetched-champion') {
    @include block('champion-card') {
      &:nth-child(10n + 1) {
        --nth-child: 0;
      }
      &:nth-child(10n + 2) {
        --nth-child: 1;
      }
      &:nth-child(10n + 3) {
        --nth-child: 2;
      }
      &:nth-child(10n + 4) {
        --nth-child: 3;
      }
      &:nth-child(10n + 5) {
        --nth-child: 4;
      }
      &:nth-child(10n + 6) {
        --nth-child: 5;
      }
      &:nth-child(10n + 7) {
        --nth-child: 6;
      }
      &:nth-child(10n + 8) {
        --nth-child: 7;
      }
      &:nth-child(10n + 9) {
        --nth-child: 8;
      }
      &:nth-child(10n + 10) {
        --nth-child: 9;
      }

      animation: championAppear 0.5s calc(0s + var(--nth-child) * 0.2s) forwards
        cubic-bezier(0, 0.5, 0.6, 1.15);
      @keyframes championAppear {
        from {
          transform: translateX(10%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }
  @include modifier('new-champion') {
    display: flex;
    flex-direction: column-reverse;
    @include block('champion-card') {
      animation: championAppear 0.5s forwards cubic-bezier(0, 0.5, 0.6, 1.15);
      @keyframes championAppear {
        from {
          transform: translateX(10%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }
}
@include block('champion-card') {
  position: relative;
  margin-top: rem(6px);
  padding: rem(10px);
  background-color: #f3ecfd;
  display: flex;
  border-radius: rem(8px);
  opacity: 0;
  @include media-breakpoint-up('lg') {
    white-space: normal;
  }

  &.always-on {
    opacity: 1;
  }
  @include element('thumbnail') {
    position: relative;
    margin-right: 10px;
    height: 86px;
    width: 72px;
    overflow: hidden;
    background-color: $--color-greyscale-200;
    border-radius: rem(8px);
    z-index: 1;

    @include element('game-icon') {
      position: absolute;
      top: 0px;
      right: 0px;
      width: rem(40px);
      height: rem(40px);
      background-color: white;
      border-radius: 8px;
    }
    @include element('new-game-icon') {
      width: 100%;
      margin-top: -100%;
      margin-left: 0px;
    }
    @include element('avatar') {
      position: absolute;
      object-fit: cover;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: rem(8px);
    }
    @include element('thumbnail-img') {
      border: none;
      background-color: transparent;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-size: cover;
      background-position: center;
    }
  }
  @include element('info') {
    z-index: 1;
    @include element('phone') {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      padding-top: rem(2px);
      line-height: 14px;
      color: $--color-greyscale-400;
      max-width: 16ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include element('title') {
      margin: 8px 0px 4px;
      font-style: normal;
      font-weight: 900;
      font-size: rem(20px);
      line-height: 0.8rem;
      color: $--color-violet-500;
    }
    @include element('sub-title') {
      margin: 0px 0px 4px;
      font-style: normal;
      font-weight: 500;
      font-size: rem(16px);
      line-height: 0.8rem;
      color: $--color-violet-500;
      text-decoration: underline;
    }
    @include element('game') {
      margin: 0px 0px 4px;
      font-style: normal;
      font-weight: 500;
      font-size: rem(16px);
      line-height: 0.8rem;
      color: $--color-violet-500;
    }
    @include element('bet-amount') {
      font-style: normal;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(14px);
      color: $--color-violet-500;
    }
    @include element('date') {
      position: absolute;
      top: rem(12px);
      right: rem(10px);
      font-style: normal;
      font-weight: 400;
      font-size: rem(16px);
      line-height: rem(16px);
      color: $--color-greyscale-400;
      text-align: right;
    }
    @include element('button') {
      background-color: transparent;
      border: none;
      position: absolute;
      bottom: rem(10px);
      right: rem(10px);
      font-style: normal;
      font-weight: 500;
      font-size: rem(20px);
      line-height: rem(20px);
      display: flex;
      align-items: center;
      color: $--color-blue-200;
      text-align: right;
      > i {
        font-size: 1.9ch;
      }
    }
  }
  @include element('background-effect') {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

@include block('champion-load-more') {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4ch;
  width: auto;
  padding: 0.5ch 2ch;
  margin: 1rem auto 0;
  text-align: center;
  color: $--color-blue-200;
  cursor: default;

  @include modifier('ended') {
    color: $--color-greyscale-600;
    opacity: 0.6;
  }
  @include modifier('clickable') {
    cursor: pointer;
  }
}

@include block('lobby-champion-slider') {
  @include block('lobby-champion') {
    display: flex;
    > * {
      width: 100%;
    }
  }
}

@include block('champion-icon-right') {
  font-weight: bold;
  font-size: 14px;
}
