@import 'libs/uikit/src/styles/global';

.container {
  margin: auto;
  max-width: 524px;
  padding: 0 16px;
  margin-bottom: 32px;

  @include media('>md') {
    max-width: 752px;
  }

  @include media('>lg') {
    max-width: 992px;
  }

  @include media('>xl') {
    max-width: 1076px;
  }

  .header {
    display: unset;

    & > :global(.title) {
      margin-bottom: 8px;
    }

    &.extra {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .padding {
    padding: 0 16px;
  }

  .body {
    margin-top: 8px default;

    &.no-margin {
      margin: unset default;
    }
  }
}

.no-padding {
  padding: 0 0;
}

.no-margin {
  margin-bottom: 0px;
}
