@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  width: 30px;
  height: 16px;
  padding: 1px 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: rem(14px);
  line-height: 16px;
  color: var(--color-white);
  user-select: none;

  &.none {
    background: var(--color-white);
  }

  &.bronze {
    background: $--vip-bronze;
  }

  &.silver {
    background: $--vip-silver;
  }

  &.gold {
    background: $--vip-gold;
  }

  &.diamond {
    background: $--vip-diamond;
  }
}
