@include block('login') {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include element('title') {
    margin-bottom: rem(1px);
    line-height: rem(10px);
  }
  @include element('banner-image') {
    object-fit: contain;
    margin-bottom: rem(8px);
  }
  @include element('phone-input') {
    width: 100% !important;
    margin-bottom: rem(24px);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(32px);
    }
  }
  @include element('text-link') {
    line-height: rem(20px);
    margin-bottom: rem(16px);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(32px);
    }
  }
  @include element('button-submit') {
    width: rem(180px);
    height: rem(40px);
    margin-bottom: rem(10px);
    font-size: rem(24px);
  }
  @include element('forgetphone') {
    width: fit-content;
    text-align: center;
    margin-bottom: rem(30px);
    font-size: rem(16px);
    color: $--color-greyscale-500;
  }

  @include element('divider') {
    color: $--color-violet-200;
    margin-bottom: rem(23px);
    height: rem(9px);
    hr {
      opacity: 1;
      width: 100%;
      height: 1px;
      margin: 0 auto;
      color: #decbfb;
    }
    span {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -9px);
      font-size: rem(18px);
      line-height: rem(18px);
      padding: rem(0 9px);
      background-color: $--color-greyscale-100;
    }
  }
  @include element('button-line-submit') {
    width: 100% !important;
    height: rem(48px);
    margin-bottom: rem(25px);
    border: 1px solid #2a2a2e;
    &:hover {
      border-color: $--color-violet-400;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(25px);
    }
  }
  @include element('line-icon') {
    margin-right: rem(10px);
  }
  @include element('line-section') {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    opacity: 1;
    color: $--color-violet-200;
    font-size: rem(18px);
    padding-bottom: rem(20px);
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $--color-violet-200;
    }
    &:not(:empty)::before {
      margin-right: 0.25em;
    }
    &:not(:empty)::after {
      margin-left: 0.25em;
    }
  }
  @include element('line-logo') {
    padding-right: rem(10px);
  }
}

@include block('forget-pin-link') {
  margin-top: rem(24px);
  line-height: rem(20px);
}
