.user-star {
  position: relative;
  width: 100%;
  height: 16px;
  max-width: calc((16px * 5) + 16px);
  user-select: none;

  svg {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
