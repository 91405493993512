@include block('ranking-card') {
  position: relative;
  display: flex;
  margin: rem(0 8px 4px 8px);
  border-radius: rem(8px);
  height: rem(44px);
  background-color: $--color-greyscale-100;

  @include media-breakpoint-up(md) {
    margin: rem(0 12px 8px 12px);
    height: rem(48px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include element('label') {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $--color-greyscale-200;
    width: rem(45px);
  }

  @include element('position') {
    color: $--color-greyscale-500;
    font-size: rem(24px);
    font-weight: 900;
    line-height: 1;
  }

  @include element('content') {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(12px 16px);
  }

  @include element('wrapper') {
    display: flex;
    flex-direction: column;
  }

  @include element('title') {
    color: $--color-greyscale-500;
    font-size: rem(20px);
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: rem(24px);
    }
  }

  @include element('description') {
    margin-top: rem(4px);
    color: $--color-greyscale-400;
    font-size: rem(18px);
    line-height: 1;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  @include element('value') {
    color: $--color-greyscale-500;
    font-size: rem(20px);
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: rem(24px);
    }
  }

  @include modifier('empty') {
    @include element('title') {
      color: $--color-greyscale-300;
    }

    @include element('value') {
      color: $--color-greyscale-300;
    }
  }

  @include modifier('active') {
    margin: rem(-12px 0 4px 0);
    border: 3px solid $--color-violet-300;
    border-radius: rem(12px);
    height: rem(50px);
    z-index: 1;

    @include media-breakpoint-up(md) {
      margin: rem(-20px 0 8px 0);
      border-radius: rem(8px);
      height: rem(54px);
    }

    @include element('title') {
      color: $--color-violet-300;
      font-weight: 900;
    }
  }

  @include modifier('reward') {
    margin: rem(0 0 4px 0);
    height: rem(66px);

    @include media-breakpoint-up(md) {
      margin: rem(0 0 8px 0);
    }
  }
}
