@import 'libs/uikit/src/styles/global';

.checkbox {
  gap: 4px;
  margin-inline-start: 0 !important;

  &:hover {
    :global(.ant-checkbox-inner) {
      border-color: var(--color-primary-1) !important;
    }
  }

  :global(.ant-checkbox-inner) {
    width: 18px;
    height: 18px;
    outline: none !important;

    &::after {
      margin-left: 1px;
    }
  }
  span {
    font-size: rem(20px);
  }
}
