@include block('error-notice-layout') {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  line-height: rem(20px);
}
@include block('-image') {
  margin-top: rem(17px);
  min-height: rem(89px);
  @include media-breakpoint-up(md) {
    min-height: rem(128px);
  }
}
@include block('error-notice-title') {
  margin-top: rem(8px);
  font-weight: 900;
}
@include block('error-notice-subtitle') {
  margin: rem(4px) auto 0 auto;
  width: rem(300px);
  color: $--color-greyscale-500;
}
