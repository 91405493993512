@include block('new-game-link-card') {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: rem(272px);
  height: rem(154px);

  > .ps-icon-play {
    display: block;
    width: rem(32px);
    height: rem(32px);
    color: $--color-blue-200;
    font-size: rem(32px);
    line-height: 1;
  }

  @include element('link') {
    margin-top: rem(8px);
    text-decoration: underline;
    text-underline-offset: 1px;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
  }
}
