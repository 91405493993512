@include block('step-kyc-input-title') {
  display: inline-flex;
  @include element('icon-info') {
    color: $--color-blue-200;
    font-size: rem(16px);
    margin-left: rem(4px);
  }
}

@include block('kyc-margin-input') {
  margin-bottom: rem(16px);
}

@include block('kyc-privacy-layout') {
  display: flex;
  align-items: flex-start;
  line-height: rem(20px);
  font-size: rem(20px);
  margin-bottom: rem(16px);
  max-width: rem(328px);

  @include element('button') {
    font-size: rem(24px);
    color: $--color-violet-400;
    margin-right: rem(8px);
    .ant-checkbox .ant-checkbox-inner {
      width: rem(24px);
      height: rem(24px);
    }
  }
  @include element('content') {
    font-family: inherit;
  }

  @include element('text-link') {
    padding-left: 0;
    .ps-text-link__body {
      text-decoration: auto;
    }
  }
}

@include block('kyc-privacy-message-layout') {
  font-size: 20px;
  line-height: 20px;
  color: $--color-greyscale-400;
  text-align: center;
  margin-bottom: rem(24px);
}

@include block('kyc-tooltip') {
  .ant-tooltip-content {
    margin-left: rem(-24px) !important;
  }
}
