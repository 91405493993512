@include block('affiliate') {
  .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    height: 45px;
    line-height: 1;
  }

  @include element('tab') {
    .ant-tabs-tab-btn {
      font-size: 28.8px;
      @include media-breakpoint-up('md') {
        font-size: 32px;
      }
    }

    .ant-tabs-nav::before {
      border-bottom: solid 2px $--color-orange-300;
    }

    &.ant-tabs-card {
      .ant-tabs-tab {
        border: none;
        background: none;

        @include media-breakpoint-up('sm') {
          min-width: 170px;
        }

        @include media-breakpoint-up('md') {
          min-width: 300px;
        }

        @include media-breakpoint-up('lg') {
          min-width: 300px;
        }

        &.ant-tabs-tab-active {
          border: solid 2px $--color-orange-300;
          border-bottom: solid 2px $--color-white;
          border-radius: 5px 5px 0 0;
        }

        div[role='tab'] {
          margin: auto;
        }

        div[aria-selected='false'] {
          color: $--color-greyscale-500;
        }

        div[aria-selected='true'] {
          color: $--color-greyscale-600;
        }
      }
    }
  }

  @include element('tac') {
    font-weight: lighter;
    color: $--color-greyscale-500;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    @include media-breakpoint-up('md') {
      font-size: 18px;
    }
  }

  @include element('incometabs') {
    max-width: 400px;
    margin: auto;

    .ant-tabs-nav-wrap::before {
      width: 0;
      justify-content: flex-start !important;
    }

    .ant-tabs-tab {
      padding: 0;
      margin: 0 0 0 15px;

      &.ant-tabs-tab-active {
        border-bottom: none;
      }

      div[aria-selected='false'] {
        font-size: 28.8px;
        color: $--color-greyscale-500;
        @include media-breakpoint-up('md') {
          font-size: 32px;
        }
      }

      div[aria-selected='true'] {
        font-size: 28.8px;
        color: $--color-greyscale-600;
        @include media-breakpoint-up('md') {
          font-size: 32px;
        }
      }
    }

    .ant-tabs-ink-bar {
      background: $--color-orange-300;
    }
  }
}

@include block('affiliate-intro') {
  @include element('card') {
    font-size: rem(26px);
    background-color: $--color-yellow-200;
    padding: rem(6px) rem(12px);
    border-radius: 8px;
    margin: rem(16px) auto;

    @include media-breakpoint-up('sm') {
      width: 75%;
      max-width: 270px;
    }

    @include media-breakpoint-up('md') {
      width: 360px;
      padding: rem(12px) rem(24px);
      max-width: initial;
    }

    @include media-breakpoint-up('lg') {
      width: 528px;
    }

    span {
      font-weight: lighter;
      font-size: 18px;

      @include media-breakpoint-up('lg') {
        font-size: 20px;
      }
    }
  }
}

.ps-header.ps-header--affiliate
  .ps-header__navbar-hamberger
  .ps-notification-badge {
  border: 2px solid $--color-orange-100;
}
