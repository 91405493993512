@include block('button-clean') {
  display: inline-block;
  border: none;
  padding: 0;
  border-radius: none;
  box-shadow: none;
  background-color: transparent;
}
@include block('chat-badge') {
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 6px;
    min-width: unset;
    height: 16px;
    font-size: 12px;
    border-radius: 12px;
    line-height: 30px !important;
    > * {
      height: fit-content !important;
    }
  }
  .ant-scroll-number .ant-scroll-number-only {
    height: 25px;
  }
}
@include block('chatbox-backdrop') {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  overflow: hidden;
  z-index: 70;
  background-color: transparent;
}
@include block('chatbox-motion') {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 71;
  background-color: transparent;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.15);
  @include media-breakpoint-up(md) {
    width: 350px;
  }
}
@include block('chatbox') {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $--color-greyscale-100;
  z-index: 71;

  @include modifier('read-only') {
    .pn-msg__actions {
      visibility: hidden;
    }
    .pn-msg-input__icons {
      pointer-events: none;
    }
    .ps-chat-message__emoji-button {
      visibility: hidden !important;
    }
    .ps-chat-message__reactions {
      pointer-events: none;
    }
  }
  @include element('pubnub-component') {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: none;
    .pn-msg-list-scroller {
      overscroll-behavior-y: none;
    }
  }

  @include element('loader') {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(201 201 201 / 77%);
    z-index: 20;
  }
  @include element('image') {
    content-visibility: visible;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
@include block('header-chatbox') {
  z-index: 30;
  @include modifier('desktop-version') {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
  }
}
@include block('chatbox-member') {
  padding-top: 12px;
  max-height: 70vh;
  overflow: hidden auto;
}
@include block('chat-channel-selected') {
  color: $--color-primary-1;
}
@include block('focus-to-fix-annoying-bug') {
  &:focus {
    border: 2px solid rgb(0, 255, 0);
  }
}
@include block('emoji-picker') {
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 30 !important;
  @include modifier('fliped') {
    transform: translateY(-100%);
  }
}
@include block('gif-picker') {
  position: absolute;
  bottom: 58px;
  z-index: 30 !important;
}

// ================= CHAT BOX V2 ===================
@include block('chat-message-scroller') {
  width: 100%;
  height: calc(100% - 60px);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  overflow-y: auto;
  margin-top: 64px;

  &:first-child {
    padding-top: 14px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 1px solid slategrey;
  }

  @include modifier('guild') {
    max-height: calc(100vh - 150px);
    margin-top: 0px;
    height: fit-content;
  }

  @include modifier('guild-mobile') {
    max-height: calc(100% - 80px);
  }
}
@include block('chat-message-list-guild') {
  position: absolute;
  width: 100%;
  height: 25px;
  margin-top: 66px;
  background-color: white;
  text-align: center;
  color: #d0b3fa;
  padding: 4px 0px;
  font-size: 24px;
  font-weight: normal;
  z-index: 2;
}
@include block('chat-message-list') {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: calc(100% - 58px) 58px;
  align-items: flex-end;
}

@include block('action-menu') {
  .ant-menu-item {
    background-color: #f0eaff;
  }
}

@include block('chat-input') {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 58px;
  padding: 4px 8px;
  background-color: #f0f3f7;
  z-index: 25;

  @include element('icon-tool') {
    display: block;
    position: relative;
    display: flex;
    padding: 16px 0px;
    width: 50px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #999999;
    overflow: hidden;
    @include modifier('inside') {
      position: absolute;
      right: 52px;
      width: 32px;
    }
  }
  @include element('emoji') {
  }
  @include element('file-input') {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 1px;
    height: 1px;
  }
  @include element('text') {
    width: 100%;
    display: flex;
    padding: 0 8px;
    align-items: center;
    @include element('text-input') {
      width: 100%;
      padding: 6px 8px;

      font-family: 'db-heavent';
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      white-space: wrap;

      overflow: hidden;
      background-color: #e4e9f0;
      color: #585858;
      border-radius: 20px;
      border: none !important;
      outline: none !important;
      box-sizing: border-box;
      resize: none;
    }
  }
  @include element('send-icon') {
  }
}
@include block('chat-guildname') {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: $--color-greyscale-100;
  user-select: none;
  z-index: 10;
  span {
    display: block;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $--color-violet-200;
    text-align: center;
    margin: auto;
  }
}
@include block('chat-modal') {
  .ant-modal-close {
    top: 15px;
  }
}
@include block('chat-close-icon') {
  font-size: 16px;
  line-height: 16px;
}
@include block('chatroom') {
  display: flex;
  padding-left: 8px;
  top: 0;
  width: 100%;
  background-color: $--color-greyscale-100;
  user-select: none;
  z-index: 10;
  background-color: rgba(252, 252, 252, 0.9);
  backdrop-filter: blur(12px);
  @include element('dropdown') {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    row-gap: 8px;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $--color-violet-200;
    text-align: center;
    margin: auto;
    background-color: grey;
    border: 1px solid red;
  }
  @include element('room') {
    min-height: 52px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 16px;
    font-size: 24px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $--color-primary-1;
    text-align: center;
    user-select: none;

    @include element('button') {
      width: auto;
      text-align: right;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      cursor: pointer;

      &:hover,
      &.active {
        .anticon {
          opacity: 1;
        }
        @include element('current-room') {
          opacity: 0.4;
        }
      }
      .anticon {
        color: $--color-primary-1;
        font-size: 0.6em;
        margin: 0 0.2em;
        transition: 0.3s;
        opacity: 0.8;
      }
      @include element('current-room') {
        color: $--color-primary-1;
        display: inline-block;
        text-align: right;
        transition: 0.3s;
        opacity: 0.8;
      }
    }
  }
}
@include block('chatroom-menu') {
  width: 115px;
}
@include block('chatroom-selector') {
  .ant-menu-item {
    margin: 0;
    padding: 0 12px;
    padding-right: 3px;
    width: calc(100% - 4px);

    &:hover {
      background-color: unset !important;
    }
  }
  .ant-popover-inner {
    padding: 0;
    border-radius: 0;
    width: 100px;
    text-align: right;
    position: absolute;
    right: 0;
  }
  @include modifier('selected') {
    background-color: #f8f3fe !important;
    border-right: 4px solid $--color-primary-1;
    padding: 0px 8px 0px 12px;
    border-radius: 0px !important;
    margin: 0 !important;
    width: 101% !important;
    margin-right: 0px !important;
  }
  @include element('badge') {
    > .ant-badge-status-dot {
      background-color: #ff4d4f;
    }
  }
  @include element('item') {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include modifier('with-badge') {
      justify-content: space-between;
    }
  }
  @include element('list') {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0px rgb(0 0 0 / 25%);
    overflow: hidden;
    user-select: none;
    @include element('item') {
      display: inline-block;
      width: 150px;
      color: $--color-primary-1;
      background-color: white;
      text-align: right;
      margin: auto;
      font-size: 22px;
      line-height: 36px;
      border-bottom: 1px solid rgba(130, 130, 130, 0.16);
      padding: 0 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;

      @include modifier('new-message') {
        position: relative;
        &::before {
          content: '';
          background-color: #c8373e;
          position: absolute;
          top: calc(50% - 3px);
          right: 8px;
          width: 6px;
          height: 6px;
          border-radius: 100%;
        }
      }
    }
  }
  @include element('text') {
    color: $--color-greyscale-600;
    font-family: 'db-heavent';
    font-size: rem(20px);
    width: 100%;
  }
}
@include block('hint-info') {
  font-size: 14px;
  line-height: 14px;
  opacity: 0.4;
}
