@include block('stepper-v2') {
  @include element('step-item') {
    .ant-steps-item-icon {
      background-color: #dedee0;
      border-color: #dedee0;

      .ant-steps-icon {
        color: $--color-black;
      }
    }

    @include modifier('process') {
      .ant-steps-item-icon {
        background-color: $--color-violet-300;
        border-color: $--color-violet-300;
      }
      .ant-steps-icon {
        color: $--color-white;
      }

      .ant-steps-item-title::after {
        background-color: #dedee0 !important;
      }
    }

    @include modifier('finish') {
      .ant-steps-item-icon {
        background-color: $--color-violet-300;
        border-color: $--color-violet-300;
      }
      .ant-steps-item-title::after {
        background-color: $--color-violet-300 !important;
      }
    }
  }
}
