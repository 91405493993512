@include block('register-toast') {
  line-height: rem(18px);

  a {
    text-decoration: underline !important;
    color: $--color-blue-200 !important;
  }

  @include element('bonus') {
    margin-top: rem(10px);
  }
}
